import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert } from 'react-bootstrap';
import Survey from '../../components/Survey/Survey';
import {
    getSurvey,
    getFetchError,
    getFetchStatus,
    getSubmitStatus,
    getTemplateData,
    getSubmitEmailStatus
} from '../../reducers/survey';
import {fetchDisabledSurvey, deleteUserResult, convertToPDF, sendTemplateToUser} from '../../actions/survey';
import '../user/SurveyPage.css';
import Email from "../../components/Survey/Email";
import Link from "react-router/es/Link";
import {Path} from "../../routes";
import {
    resetSaveTemplateRequest,
    saveTemplateRequest,
    saveTemplateRequestFail,
    saveTemplateRequestSuccess
} from "../../actions/login";
import {updateSavedTemplate} from "../../actions/edit_survey";

class DisabledSurveyPage extends React.Component {
  loadData() {
    this.props.fetchDisabledSurvey(this.props.surveyId,this.props.currentUser.id);
  }

  componentDidMount() {

  }

    componentWillReceiveProps(newProps){
      if(newProps.isDeleteSuccess){
          this.props.router.push('user/surveys');

      }
    }
    componentWillMount(){
        this.loadData();
        this.state = { templateDemo: {} }
    }

  render() {
    return (
        <div className="container" style={{marginBottom:'40px'}}>
          {this.props.templateDemo ? '' : <Alert bsStyle="danger">No template Found</Alert>}
          <div className="row SurveyPage">
              <div className="col-xs-2 pull-right" style={{padding:'5px 0px'}}>
                  <Link to={Path.editDisbaledUserQuestions(this.props.surveyId)} >  <button className="btn btn-primary"  >Modify</button></Link>
              </div>
            <div className="col-md-8 col-md-offset-2 survey">
                {this.props.templateDemo &&
                <Survey {...this.props}/>
                }
            </div>
             <div className="col-md-8 col-md-offset-2 survey">
                  {this.props.templateDemo &&
                  <Email {...this.props}/>
                  }
            </div>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state, { params }) => {
  return {
    survey: getSurvey(state.survey),
    isLoading: getFetchStatus(state.survey),
    error: getFetchError(state.survey),
    surveyId: params.surveyId,
    isDeleteSuccess: getSubmitStatus(state.survey),
    isEmailSuccess  : getSubmitEmailStatus(state.survey),
    templateDemo:getTemplateData(state.survey),
    currentUser: state.session.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitSave: bindActionCreators(updateSavedTemplate, dispatch),
    onSubmit: bindActionCreators(deleteUserResult, dispatch),
    onSubmitPdf: bindActionCreators(convertToPDF, dispatch),
    onSubmitEmail: bindActionCreators(sendTemplateToUser, dispatch),
    fetchDisabledSurvey: bindActionCreators(fetchDisabledSurvey, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisabledSurveyPage);
