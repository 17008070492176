export default function(state={isRegisterSuccess: false, isLoading: false}, action) {
  switch (action.type) {
    case 'REGISTER_DEFAULT':
        return {
            ...state,
            isLoading: true,
            isRegisterSuccess: false
        };
    case 'REGISTER_REQUEST':
      return {
        ...state,
        isLoading: true,
        isRegisterSuccess: false
      };
    case 'REGISTER_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isRegisterSuccess: true
      };
    case 'REGISTER_REQUEST_FAIL':
      return {
        ...state,
        isLoading: false,
        isRegisterSuccess: false
      };
    default:
      return state;
  }
};
