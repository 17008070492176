import React from 'react';
import NewSurvey from '../../containers/NewSurvey';
import SurveyList from '../../containers/SurveyList';
import '../user/UserSurveysPage.css';
import {Link} from "react-router";

class AdminSurveyPage extends React.Component {
    render() {
        return (
            <div className="container UserSurveysPage">

                <h5 style={{textAlign:'right',textDecoration:'underline'}}> <Link to='admin/users'>Users Dashboard</Link></h5>
                <NewSurvey />
                <SurveyList/>
            </div>
        );
    }
}

export default AdminSurveyPage;
