import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {deleteSurvey, updateModifiedSurvey, updateSavedTemplate, updateSurvey} from '../actions/edit_survey';
import { assembleSurvey, getSurvey } from '../reducers/edit_survey';
import { Path } from '../routes';
import EditFooterView from './EditPanel/EditFooter';
/*import {assembleModifiedSurvey} from "../reducers/edit_modified_survey";*/

class EditFooter extends React.Component {
  componentWillUnmount() {
    this.props.resetDeleteState();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isUpdateSuccess) {
      /*this.props.push(Path.getUserModifiedSavedTemplate(this.props.survey));
      this.props.push(Path.getUserModifiedSurvey(this.props.survey));*/
        this.props.push(Path.getUserModifiedSavedTemplate(this.props.survey));
    }
  }

  render() {
    return <EditFooterView {...this.props}/>
  }
}

const mapStateToProps = (state, { router }) => {
  return {
    survey: assembleSurvey(getSurvey(state.edit_survey)),
    isDeleteSuccess: state.edit_survey.deleteSurvey.isSuccess,
    isUpdateSuccess: state.edit_survey.updateSurvey.isSuccess,
    push: router.push
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDelete: bindActionCreators(deleteSurvey, dispatch),
    onSave: bindActionCreators(updateSavedTemplate, dispatch),
    resetDeleteState: () => dispatch({
      type: 'RESET_DELETE_SURVEY_REQUEST'
    })
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditFooter));
