import axios from 'axios';
import decode from 'jwt-decode';
// or if you prefer no Base64 namespace
import {Base64} from 'js-base64';

const fetcher = axios.create({
  /*baseURL: 'http://localhost:3001/project/v1',*/
   baseURL: 'http://3.129.137.216:3001/project/v1',
   headers: {
    'Content-Type': 'application/json',
    'Authorization': localStorage.session
  }
});

export const createUser = (params) => { 
  return fetcher.post("/user/register", params).then(res => res.data);
};

export const login = (email, password) => {
  return fetcher.post("/user/login", {
    email,
    password
  }).then(res => {

    localStorage.session = res.data.auth;
    fetcher.defaults.headers.common['Authorization'] = res.data.auth;
    return decode(res.data.auth);
  })/*.catch((error)=>{

  });*/
};

export const forgetPassword = (email) => {
    return fetcher.post("/user/forget-password", {email}).then(res => { return res.data; });
};

export const resetPassword = (email,activationcode,password,rpassword) => {
    return fetcher.post("/user/reset-password", {email,activationcode,password,rpassword}).then(res => { return res.data; });
};

export const activateTemplate = (email,templateId) => {
    return fetcher.post("/user/get-template-from-link", {email,templateId}).then(res => { return res.data; });
};


export const logout = () => {
  delete localStorage.session;
  return Promise.resolve();
};

export const fetchCurrentUser = () => {
  return fetcher.get("/user/users/me").then(res => {
    return res.data;
  });
};

export const fetchUserSurveys = (user) => {
  return fetcher.get(`/user/${user.id}/getAllSurveys`).then(res => res.data);
};

export const fetchSecondaryUserSurveys = (user) => {
    return fetcher.post(`/user/get-templates-user`,{email:user.email}).then(res => res.data);
};

export const fetchUserPersonalSurveys = (user) => {
    return fetcher.get(`/user/${user.id}/getAllUserSurveys`).then(res => res.data);
};

export const fetchUserModifiedPersonalSurveys = (user) => {
    return fetcher.post(`/user/${user.id}/getAllModifiedSurveys`,{user}).then(res => res.data);
};

export const fetchUserAdminPersonalSurveys = (userId) => {
    return fetcher.get(`/user/admin/${userId}/getAllUserSurveysForAdmin`).then(res => res.data);
};

export const fetchResults = (surveyId) => {
  return fetcher.get(`/user/surveys/${surveyId}/results`).then(res => res.data);
};

export const fetchUsers = () => {
    return fetcher.get('/user/getAllUser').then(res => res.data);
};
export const blockUser = (id) => {
    return fetcher.post('/user/disable-user',{id:id}).then(res => res.data);
};
export const unBlockUser = (id) => {
    return fetcher.post('/user/enable-user',{id:id}).then(res => res.data);
};

export const deleteUser = (id) => {
    return fetcher.post('/user/delete-user',{id:id}).then(res => res.data);
};



export const createSurvey = (userId, initSurvey) => {
  return fetcher.post(`/user/${userId}/initUserSurveys`, initSurvey).then(res => res.data);
};

export const saveResult = (surveyId, result) => {
  return fetcher.post(`/user/surveys/${surveyId}/results`, result);
};


export const saveTemplate = (template,) => {
    return fetcher.post(`/user/saveTemplate`,template);
};


export const fetchSurvey = (surveyId) => {
    return fetcher.get(`/user/getAdminSurvey/${surveyId}`).then(res => res.data);
};

export const fetchModifiedSurvey = (surveyId) => {
    return fetcher.get(`/user/getModifiedSurvey/${surveyId}`).then(res => res.data);
};

export const fetchDisabledSaveTemplate = (surveyId) => {
    return fetcher.get(`/user/getDisabledSavedTemplate/${surveyId}`).then(res => res.data);
};

export const fetchDisabledSurvey = (surveyId,userId) => {
    return fetcher.post('/user/getUserSurvey/',{surveyId:surveyId,userId:userId}).then(res => res.data);
};

export const fetchDisabledSecondarySurvey = (surveyId) => {
    return fetcher.post('/user/getSecondaryUserSurvey/',{surveyId:surveyId}).then(res => res.data);
};


export const convertToPdf = (complateSurvey) => {
    return fetcher.post('/user/json-to-pdf/',complateSurvey).then((response)=> {

        const binaryImg = atob(response.data.data);
        const length = binaryImg.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < length; i++) {
            uintArray[i] = binaryImg.charCodeAt(i);
        }

        const fileBlob = new Blob([uintArray], { type: 'application/pdf' });
        var blobURL = URL.createObjectURL(fileBlob);
        window.open(blobURL);
     })
};

export const deleteUserTemplate = (surveyId,userId) => {
    return fetcher.post('/user/deleteUserTemplate/',{surveyId:surveyId,userId:userId}).then(res => res.data);
};


export const deleteSurvey = surveyId => {
    return fetcher.post(`/user/delete/survey/`,{id:surveyId}).then(res => res.data);
};


export const deleteSurveyModified = surveyId => {
    return fetcher.post(`/user/delete/surveyModified/`,{id:surveyId}).then(res => res.data);
};

export const updateSurvey = (survey) => {
  return fetcher.put(`/user/surveys/${survey._id}`, survey).then(res => res.data);
};

export const updateModifySurvey = (survey,user) => {
    return fetcher.put(`/user/surveysModified/${survey._id}`,{survey,user}).then(res => res.data);
};

export const updateSavedTemplate = (survey) => {
    return fetcher.put(`/user/updateSavedTemplate/${survey._id}`, survey).then(res => res.data);
};



export const deleteResults = (surveyId, results) => {
  return Promise.all(results.map(result => fetcher.delete(`/user/surveys/${surveyId}/results/${result.id}`)));
};

export const sendTemplateToUser = (template) => {
    return fetcher.post(`/user/send-template-link`,template).then(res => res.data);
};

export const sendTemplateLink = (template) => {
    return fetcher.post(`/user/send-template-link`,template).then(res => res.data);
};