import React from 'react';
import PropTypes from "prop-types";
import './../../components/UserFilledTable/SurveyItem.css';
import UserModifiedSurveyItem from "./UserSurveyItem";

class UserModifiedSurveyListView extends React.Component {

    render() {
        let { userModifiedSurveys } = this.props;
        return (
            <div className="SurveyList row">
                <ul className="list-unstyled">
                    {userModifiedSurveys.map(userModifiedSurvey => {
                        return <li key={userModifiedSurvey._id} className="col-md-3"><UserModifiedSurveyItem userModifiedSurvey={userModifiedSurvey}/></li>
                    })}
                </ul>
            </div>
        );
    }
}

UserModifiedSurveyListView.propTypes = {
    userModifiedSurveys: PropTypes.array.isRequired
};

export default UserModifiedSurveyListView;
