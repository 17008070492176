import React from 'react';
import PropTypes from "prop-types";
import './SurveyList.css';
import SecondaryUserSurveyItem from "./SecondaryUserSurveyItem";

class SecondaryUserSurveyListView extends React.Component {

    render() {
        let { secondaryUserSurveys } = this.props;
        return (
            <div className="SurveyList row">
                <ul className="list-unstyled">
                    {secondaryUserSurveys.map(secondaryUserSurvey => {
                        if(secondaryUserSurvey){
                            return <li key={secondaryUserSurvey._id} className="col-md-3"><SecondaryUserSurveyItem secondaryUserSurvey={secondaryUserSurvey}/></li>
                        }

                    })}
                </ul>
            </div>
        );
    }
}

SecondaryUserSurveyListView.propTypes = {
    secondaryUserSurveys: PropTypes.array.isRequired
};

export default SecondaryUserSurveyListView;
