import * as React from 'react';
import QuestionWrapper from './QuestionWrapper';
import {Field, Formik as setFieldValue, withFormik} from 'formik';
import './Survey.css';
// import this in the related component
import { useFormikContext } from 'formik';
import errors from "../ForgetPasswordForm";

type Props = {
  survey: object,
  isLoading: bool,
  error: object,
  onSubmit: func,
  onSubmitPdf:func,
  isEmailSuccess: bool,
  onSubmit: func
};

const renderInput = ({field, form: { touched, errors }, ...props}) =>
    <div>
        <input {...field.input} {...field} {...props} className="form-control" />
        {
            touched[field.name] &&
            errors[field.name] &&
            <span className="help-block">{field.meta.error}</span>
        }
    </div>;

class Email extends React.Component<Props> {
    constructor(props) {
        super(props);
    }
  renderError() {
    return <div>Error!</div>
  }

  renderLoading() {
    return <div>Loading...</div>
  }

  renderSuccess() {


      return <div>Email Sent Please Check...</div>

  }

  render() {
      /*let { , handleSubmit, errors, touched } = this.props;*/
      let { isSubmitting,isLoading, error, handleSubmit, isEmailSuccess } = this.props;
    let { templateSecondaryDemo } = this.props.templateSecondaryDemo;

    if (isLoading) {
      return this.renderLoading();
    }

    if (error) {
      return this.renderError();
    }

    if (!templateSecondaryDemo) {
      return <div/>;
    }

    /*if (isEmailSuccess) {
      return this.renderSuccess();
    }*/

      function onSubmit(values) {
          // Do stuff here...
          alert(JSON.stringify(values, null, 2));
      }

    return (

        <div className="Survey">
            {templateSecondaryDemo  &&
            <form onSubmit={handleSubmit}>
                <legend>Send Email</legend>
                {errors.message && <div className="alert alert-danger" role="alert">{errors.message}</div>}
                <div className="form-group">
                    <label htmlFor="">Email</label>
                    <Field
                        name="username"
                        component={renderInput}
                        placeholder="admin@example.com"
                        type="text"/>
                </div>
                {isSubmitting ?
                    <button className="btn btn-primary" type="submit" disabled>Loading...</button> :
                    <button className="btn btn-primary" type="submit">Send Email</button>}
            </form>
            }
        </div>
    );
  }
}

export default withFormik({
    mapPropsToValues: () => {},
    handleSubmit: (values, { props, setSubmitting,resetForm, setErrors }) => {
        const sendUserInfo={email:values.username,
            templateId:props.templateSecondaryDemo.templateSecondaryDemo._id,
            userId:props.templateSecondaryDemo.templateSecondaryDemo.userId
        };
        props.onSubmitEmail(sendUserInfo);
        setTimeout(()=>{
            resetForm();
            setSubmitting(false);
        },2000)
    }
})(Email);
