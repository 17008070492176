import React from 'react';
import PropTypes from 'prop-types';
import './EditSurveyPanel.css';

class EditSurveyPanel extends React.Component {

  update() {
    this.props.onUpdate({
      title: this.title.value
    /*  subTitle: this.sub_title.value*/
    });
  }

  render() {
    let { title } = this.props;
    return (
      <div className="EditSurveyPanel">
        <div className="form-group">
          <label>Title</label>
          <input
              type="text"
              className="form-control"
              value={title}
              ref={(input) => { this.title = input }}
              onChange={this.update.bind(this)}/>
        </div>
       {/* <div className="form-group">
          <label htmlFor="">SubTitle</label>
          <textarea
              className="form-control"
              value={sub_title}
              ref={input => { this.sub_title = input }}
              onChange={this.update.bind(this)} />
        </div>*/}
      </div>
    );
  }
}

EditSurveyPanel.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default EditSurveyPanel;
