export default function(state={isResetSuccess: false, isLoading: false}, action) {
  switch (action.type) {
    case 'RESET_REQUEST':
      return {
        ...state,
        isLoading: true
      };
    case 'RESET_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isResetSuccess: true
      };
    case 'RESET_REQUEST_FAIL':
      return {
        ...state,
        isLoading: false,
        isResetSuccess: false
      };
    default:
      return state;
  }
};
