import * as api from '../api';
import { fetchSurveysRequest } from './surveys';
import {
    FETCH_DISABLED_USER_SURVEY_REQUEST,
    FETCH_DISABLED_USER_SURVEY_REQUEST_FAIL,
    FETCH_DISABLED_USER_SURVEY_REQUEST_SUCCESS
} from "../reducers/survey";
import {toast} from "react-toastify";

export const loginRequest = (email, password) => dispatch => {
  dispatch({
    type: 'LOGIN_REQUEST',
    payload: {
      email,
      password
    }
  });
  return api.login(email, password);
};

export const loginRequestSuccess = (res) => (dispatch) => {
  dispatch({
    type: 'LOGIN_REQUEST_SUCCESS',
    payload: res
  });
  dispatch(fetchSurveysRequest(res));
};

export const loginRequestFail = (res) => ({
  type: 'LOGIN_REQUEST_FAIL',
  payload: res
});



export const saveTemplateRequest = (values) => dispatch => {
    dispatch({
        type: 'SAVE_TEMPLATE_REQUEST',
        payload: {
            values
        }
    });
    return api.saveTemplate(values);
};

export const saveTemplateRequestSuccess = (res) => (dispatch) => {
    dispatch({

        type: 'SAVE_TEMPLATE_REQUEST_SUCCESS',
        payload: res
    });
};

export const saveTemplateRequestFail = (err) => ({
    type: 'SAVE_TEMPLATE_REQUEST_FAIL',
    payload: err
});


export const resetSaveTemplateRequest= (res) => ({
    type: 'RESET_SAVE_TEMPLATE_REQUEST',
    payload: res
});




export const fetchCurrentUserRequest = () => dispatch => {
  dispatch({
    type: 'CURRENT_USER_REQUEST'
  });
  return api.fetchCurrentUser();
};

export const fetchCurrentUserRequestSuccess = (res) => ({
  type: 'CURRENT_USER_REQUEST_SUCCESS',
  payload: res
});

export const fetchCurrentUserRequestFail = (prevPath) => ({
  type: 'CURRENT_USER_REQUEST_FAIL',
  payload: prevPath
});


export const ForgetPasswordRequest = (email) => dispatch => {
    dispatch({
        type: 'FORGET_REQUEST',
        payload: {
            email,
        }
    });
    return api.forgetPassword(email);
};

export const ForgetPasswordSuccess = (res) => (dispatch) => {
    dispatch({
        type: 'FORGET_REQUEST_SUCCESS',
        payload: res
    });
};

export const ForgetPasswordFail = (err) => ({
    type: 'FORGET_REQUEST_FAIL',
    payload: err
});



export const ForgetPasswordEmailSent = (res) => dispatch => {
    dispatch({
        type: 'FORGET_REQUEST_EMAIL_SENT',
        payload: res
    });
};



export const ResetPasswordRequest = (email,key,password,rpassword) => dispatch => {
    dispatch({
        type: 'RESET_REQUEST',
        payload: {
            email,
            password,
            rpassword,
            key
        }
    });
    return api.resetPassword(email,key,password,rpassword);
};

export const ResetPasswordSuccess = (res) => (dispatch) => {
    dispatch({
        type: 'RESET_REQUEST_SUCCESS',
        payload: res
    });
};

export const ResetPasswordFail = (err) => ({
    type: 'RESET_REQUEST_FAIL',
    payload: err
});




export const activateUserTemplate = (email, templateId) => dispatch => {
    dispatch({
        type: 'ACTIVATION_REQUEST',
        payload: {
            email,
            templateId
        }
    });

    api.activateTemplate(email,templateId).then(res => {
        dispatch({
            type: 'ACTIVATION_REQUEST_SUCCESS',
            payload: res
        });
        toast.success('Template has been succesfully loaded');
    }).catch(err => {
        dispatch({
            type: 'ACTIVATION_REQUEST_FAIL',
            payload: err

        });

        toast.error( err.response.data.message);
    });
};


