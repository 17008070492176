import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginFormView from '../../components/LoginForm';
import { loginRequest, loginRequestSuccess, loginRequestFail } from '../../actions/login';
import {adminHome, secondaryUserHome, userHome} from '../../reducers/session';


class LoginPage extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoginSuccess) {
        if(nextProps.currentUser.role === 'admin'){
            this.props.router.push(adminHome(nextProps.currentUser));
        }else if(nextProps.currentUser.role === 'group-secondary'){
            this.props.router.push(secondaryUserHome(nextProps.currentUser));
        }
        else{
            this.props.router.push(userHome(nextProps.currentUser));
        }
    }
  }

  render() {
    return (
        <div className="col-md-4 col-md-offset-4" style={{marginTop: '40px'}}>
          <LoginFormView {...this.props}/>
            <div className="text-center"  style={{width:'100%',marginTop:'10px'}} >
                <a href="#/forget-password" style={{color:'blue',cursor:'pointer'}}>Forgot Password?</a>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoginSuccess: state.session.isSuccess,
    currentUser: state.session.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      return dispatch(loginRequest(values.username, values.password)).then(res => {

        dispatch(loginRequestSuccess(res));
        return Promise.resolve(res);
      }).catch(err => {
        dispatch(loginRequestFail(err));
        return Promise.reject(err);
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
