export default function (state = {secondaryUserSurveys: [], isLoading: false, error: null}, action) {
  switch (action.type) {
    case 'FETCH_SECONDARY_USER_SURVEYS_REQUEST':
      return {
        isLoading: true,
        error: null,
          secondaryUserSurveys: []
      };
    case 'FETCH_SECONDARY_USER_SURVEYS_REQUEST_SUCCESS':
      return {
        isLoading: false,
        error: null,
          secondaryUserSurveys: action.payload

      };
    case 'FETCH_SECONDARY_USER_SURVEYS_REQUEST_FAIL':
      return {
        isLoading: false,
        error: action.payload,
          secondaryUserSurveys: []
      };
    case 'CREATE_SECONDARY_USER_SURVEY_REQUEST':
      return {
        ...state,
        isCreating: true,
        error: null
      };
    case 'CREATE_USER_SURVEY_REQUEST_SUCCESS':
      return {
        ...state,
        isCreating: false,
        error: null
      };
    case 'CREATE_SECONDARY_USER_SURVEY_REQUEST_FAIL':
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };
    default:
      return state;
  }
}
