import * as React from 'react';
import QuestionWrapper from './QuestionWrapper';
import {Formik as setFieldValue, withFormik} from 'formik';
import './Survey.css';
// import this in the related component
import { useFormikContext } from 'formik';

type Props = {
  survey: object,
  isLoading: bool,
  error: object,
  onSubmit: func,
  onSubmitPdf:func,
  isSuccess: bool
};

class SecondarySurvey extends React.Component<Props> {
    constructor(props) {
        super(props);
    }
  renderError() {
    return <div>Error!</div>
  }

  renderLoading() {
    return <div>Loading...</div>
  }

  renderSuccess() {
    return <div>
      <h1>Submit Success!</h1>
    </div>
  }

  render() {
      let {  isLoading, error, handleSubmit, isSuccess } = this.props;
    let { survey } = this.props;
    let { title, subTitle, questions,images,videos} = survey;

    if (isLoading) {
      return this.renderLoading();
    }

    if (error) {
      return this.renderError();
    }

    if (!survey) {
      return <div/>;
    }

    if (isSuccess) {
      return this.renderSuccess();
    }

      function onSubmit(values) {
          // Do stuff here...
          alert(JSON.stringify(values, null, 2));
      }

    return (

        <div className="Survey">
            {survey  &&
                <form onSubmit={handleSubmit}>
                    <header>
                        <h3>{title}</h3>
                        <p>{subTitle}</p>
                    </header>
                    <ul className="list-unstyled">
                        {questions.map(question => {
                            return <li key={question._id}><QuestionWrapper question={question}/></li>
                        })}
                    </ul>
                    <div className="row">
                        { images && images.length > 0  && images.map((file, index) => {
                            return (
                                <div className="col-md-6" style={{padding:20}}>

                                    {/* <video className="img-responsive" src={URL.createObjectURL(file)}   />*/}
                                    {file &&
                                    <div>
                                        {/* <span className="glyphicon glyphicon-remove" aria-hidden="true" onClick={()=>this.deleteVideo(file, index)}  />*/}
                                        <div>
                                            {/*<span className="glyphicon glyphicon-remove" aria-hidden="true" onClick={()=>this.deleteImage(file, index)}  />*/}
                                            <img className="img-responsive" src={file['Location'] ? file['Location'] : URL.createObjectURL(file)}   />
                                        </div>
                                    </div>
                                    }

                                </div>
                            );
                        })}
                    </div>
                  <div className="row">
                    { videos && videos.length > 0  && videos.map((file, index) => {
                        return (
                            <div className="col-md-6" style={{padding:20}}>

                                {/* <video className="img-responsive" src={URL.createObjectURL(file)}   />*/}
                                {file &&
                                <div>
                                   {/* <span className="glyphicon glyphicon-remove" aria-hidden="true" onClick={()=>this.deleteVideo(file, index)}  />*/}
                                    <video width="320" height="240" controls>
                                        <source src={file['Location'] ? file['Location'] : URL.createObjectURL(file)}  type="video/mp4"/>
                                    </video>
                                </div>
                                }

                            </div>
                        );
                    })}
                  </div>

                    {/*<ul className="list-unstyled">
                        {videos.map((video,index) => {
                            return <li key={index}><img src={video['Location']} height="200" width="200" alt="2013 Toyota Tacoma" id="itemImg"/></li>
                        })}
                    </ul>*/}
                   {/* <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <button type="submit" onClick={this.props.onSubmit}  className="btn btn-primary">Delete</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <button type="submit"  className="btn btn-primary">Convert</button>
                            </div>
                        </div>
                    </div>*/}
                </form>
            }
        </div>
    );
  }
}

export default withFormik({
  mapPropsToValues: (state) => {
  },
  handleSubmit: ( values, { props }) => {
    props.onSubmitPdf(props.templateSecondaryDemo,props.currentUser.id);
  }
})(SecondarySurvey);
