import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../user/SurveyPage.css';
import {blockUser, deleteAdminUser, deleteUser, fetchUsers, unBlockUser} from "../../actions/data";
import Link from "react-router/es/Link";
import {Path} from "../../routes";

class UsersPage extends React.Component {
    loadData() {
        this.props.fetchUsers();
    }

    componentDidMount() {

    }

    componentWillMount(){
        this.loadData();
    }

    render() {
        const { users } = this.props;
        return (
            <div className="container">
                <div  className="container h-100 w-100  text-left">
                    <h1 className="text-center">Number of Users</h1>
                    <div className="row mt-5">
                        <div className="col-xs-12 table-responsive"   id="page-content-wrapper">
                            <table  className="table table-striped header-fixed" >
                                <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Logs</th>
                                    <th>View(All Templates)</th>
                                    <th>Block/Unblock</th>
                                    <th>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {users && users.length > 0 && users.map((user) => {
                                    return (
                                        <tr key={user._id}>
                                            <td> {user.email}</td>
                                            <td> {user.role}</td>
                                            <td> date:{user.timestamp.substring(0, 10) }<br></br>
                                                time:{user.timestamp.match(/\d\d:\d\d/)}</td>
                                            <td> <Link  to={Path.getUserAllTemplates(user._id)}><button className="btn btn-primary" >View</button></Link></td>
                                            <td>{
                                                user.accessStatus? <button className="btn btn-danger" onClick={ () => this.props.blockUser(user._id) }  >Block</button>:
                                                    <button className="btn btn-success" onClick={ () => this.props.unBlockUser(user._id) }  >UnBlock</button>
                                                 }
                                               </td>
                                            <td> <button className="btn btn-info" onClick={ () => this.props.deleteAdminUser(user._id) }>Delete</button></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, { params }) => {
    return {
        users: state.data.users
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: bindActionCreators(fetchUsers, dispatch),
        blockUser: bindActionCreators(blockUser, dispatch),
        unBlockUser: bindActionCreators(unBlockUser, dispatch),
        deleteAdminUser: bindActionCreators(deleteAdminUser, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
