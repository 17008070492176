import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SecondaryUserSurveyListView from './SecondaryUserSurveyListView';
import {fetchSecondaryUserSurveysRequest, fetchUserSurveysRequest} from '../../actions/userSurveys';
import secondaryUserSurveys from "../../reducers/secondaryUserSurveys";

class   SecondaryUserSurveyList extends React.Component {
    loadData() {
        this.props.fetchSecondaryUserSurveys(this.props.currentUser);
    }

    componentWillMount() {
        this.loadData();
    }

    renderLoading() {
        return <div>Loading...</div>
    }

    renderError() {
        return <div>Load Error</div>
    }

    render() {
        const { secondaryUserSurveys, isLoading, loadError } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        if (loadError) {
            return this.renderError();
        }

        return (
           <div>
                <SecondaryUserSurveyListView secondaryUserSurveys={secondaryUserSurveys}/>
           </div>

        );
    }
}

const mapStateToProps = (state) => (
    {
    secondaryUserSurveys: state.secondaryUserSurveys.secondaryUserSurveys,
    isLoading: state.surveys.isLoading,
    loadError: state.surveys.error,
    currentUser: state.session.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSecondaryUserSurveys: bindActionCreators(fetchSecondaryUserSurveysRequest, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryUserSurveyList);
