import * as React from 'react';
import {Field, withFormik} from 'formik';
import './../components/Survey/Survey.css';
// import this in the related component
import { useFormikContext } from 'formik';

type Props = {
  survey: object,
  isLoading: bool,
  error: object,
  onSubmit: func,
  onSubmitPdf:func,
  isEmailSuccess: bool,
  onSubmit: func
};

const renderInput = ({field, form: { touched, errors }, ...props}) =>
    <div>
        <input {...field.input} {...field} {...props} className="form-control" />
        {
            touched[field.name] &&
            errors[field.name] &&
            <span className="help-block">{field.meta.error}</span>
        }
    </div>;

class Email extends React.Component<Props> {
    constructor(props) {
        super(props);
    }
  renderError() {
    return <div>Error!</div>
  }

  renderLoading() {
    return <div>Loading...</div>
  }

  renderSuccess() {


      return <div>Email Sent Please Check...</div>

  }

  render() {
      /*let { , handleSubmit, errors, touched } = this.props;*/
      let { isSubmitting,isLoading, error, handleSubmit, isEmailSuccess } = this.props;
    /*let { templateDemo } = this.props;
    let { title, subTitle, questions,images } = templateDemo;*/

    if (isLoading) {
      return this.renderLoading();
    }

    if (error) {
      return this.renderError();
    }


    if (isEmailSuccess) {
      return this.renderSuccess();
    }

    return (

        <div className="Survey">
            {this.props  &&
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <Field className="form-control"
                        name="username"
                        component={renderInput}
                        placeholder="admin@example.com"
                        type="text"/>
                       <span className="input-group-btn" style={{padding:'0px'}}>
                          {isSubmitting ?
                              <button className="btn btn-primary" type="submit"  style={{height:'45px'}} disabled>Loading...</button> :
                              <button className="btn btn-primary" type="submit"  style={{height:'45px'}}>Send Link !</button>}
                                    }
                       </span>
                </div>
            </form>
            }
        </div>
    );
  }
}

export default withFormik({
    mapPropsToValues: () => {},
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        const sendUserInfo={email:values.username,
            templateId:props._id,
            userId:props.userId
        };
        props.onSubmitEmail(sendUserInfo);
        values.username='';
        setTimeout(()=>{
            setSubmitting(false)
        },2000)

    }
})(Email);
