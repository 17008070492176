import React from 'react';
import { Link } from 'react-router';
import { Path } from '../../routes';
import './SurveyItem.css';
import {connect} from "react-redux";

class SecondaryUserSurveyItem extends React.Component {

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isSuccess) {
            this.props.resetCreateSurvey();
            this.props.router.push(Path.editSurvey(this.props.newSurvey));
        }
    }
    render() {
        let {secondaryUserSurvey, secondaryUserSurvey: { title } } = this.props;
        return (
            <div className="SurveyItem">
                <Link to={Path.disabledSecondaryUser(secondaryUserSurvey)}>{title}</Link>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.session.user
    }
};

SecondaryUserSurveyItem.propTypes = {};
SecondaryUserSurveyItem.defaultProps = {};


export default connect(mapStateToProps)(SecondaryUserSurveyItem);

