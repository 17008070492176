import { Path } from '../routes';


var initialStateTemplate = { isSaveTemplateSuccess: false, isLoading: false, user: null, prevPath: null};

export default function (state = initialStateTemplate, action) {
    switch (action.type) {
        case 'SAVE_TEMPLATE_REQUEST_':
            return {
                ...state,
                isLoading: true

            };
        case 'SAVE_TEMPLATE_REQUEST_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isSaveTemplateSuccess: true,
                user: action.payload
            };
        case 'SAVE_TEMPLATE_REQUEST_FAIL':
            return {
                ...state,
                isLoading: false,
                isSaveTemplateSuccess: false
            };
        case 'RESET_SAVE_TEMPLATE_REQUEST':
            return initialStateTemplate;
        default:
            return state;
    }
};


export const adminHome = (user) => {
  return Path.adminPage();
};

export const userHome = (user) => {
    return Path.userPage();
};