export default function (state = {userModifiedSurveys: [], isLoading: false, error: null}, action) {
  switch (action.type) {
    case 'FETCH_USER_MODIFIED_SURVEYS_REQUEST':
      return {
        isLoading: true,
        error: null,
        userModifiedSurveys: []
      };
    case 'FETCH_USER_MODIFIED_SURVEYS_REQUEST_SUCCESS':
      return {
        isLoading: false,
        error: null,
          userModifiedSurveys: action.payload

      };
    case 'FETCH_USER_MODIFIED_SURVEYS_REQUEST_FAIL':
      return {
        isLoading: false,
        error: action.payload,
        userModifiedSurveys: []
      };
    case 'CREATE_USER_MODIFIED_SURVEY_REQUEST':
      return {
        ...state,
        isCreating: true,
        error: null
      };
    case 'CREATE_USER_MODIFIED_SURVEY_REQUEST_SUCCESS':
      return {
        ...state,
        isCreating: false,
        error: null
      };
    case 'CREATE_USER_MODIFIED_SURVEY_REQUEST_FAIL':
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };
    default:
      return state;
  }
}
