import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResetPasswordForm from "../../components/ResetPasswordForm";
import {ResetPasswordFail, ResetPasswordRequest, ResetPasswordSuccess} from "../../actions/login";
import reset from "../../reducers/reset-password";


class ResetPasswordPage extends Component {
    componentWillReceiveProps(nextProps) {
        if (nextProps.isLoginSuccess) {
            if(nextProps.currentUser.role === 'admin'){
                this.props.router.push('/login');
            }
        }
    }

    render() {
        return (
            <div className="col-md-4 col-md-offset-4" style={{marginTop: '40px'}}>
                {this.props.isResetSuccess &&
                <a style={{color:'green'}}>Email has been sucessfully reset,Please Login with new user and password</a>
                }
                <ResetPasswordForm {...this.props}/>
            </div>
        )
    }
}

const mapStateToProps = (state,{ params }) => {
    return {
        isResetSuccess:state.reset.isResetSuccess,
        currentUser: state.session.user,
        emailId: params.email,
        key: params.key
    }
};

const mapDispatchToProps = (dispatch,{ params }) => {
    return {
        onSubmit: (values) => {
            return dispatch(ResetPasswordRequest(params.email,params.key,values.password,values.rpassword)).then(res => {
                dispatch(ResetPasswordSuccess(res));
                return Promise.resolve(res);
            }).catch(err => {
                dispatch(ResetPasswordFail(err));
                return Promise.reject(err);
            });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
