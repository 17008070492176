// @flow

import * as React from "react";
import {Field, withFormik} from 'formik';

type Props = {
  _id: string,
  title: string,
  placeholder: string,
  answer:string
};

class SingleLineText extends React.Component<Props> {



  render() {
    const { question} = this.props;
    const { title, placeholder,_id}=question;

    return (
        <div>
            <h3 className="question-title">{title}</h3>
            { question.answer && question.answer === "" ?
                (
                    <div className="form-group">
                        <Field type="text" className="form-control" value={placeholder} placeholder={placeholder} name={_id} component='input' />
                    </div>
                ):(
                    <div className="form-group">
                        <Field type="text" className="form-control"   name={'answer'+_id} value={ question.answer}  component='input'/>
                    </div>
                )

            }

        </div>
    );
  }
}

export default SingleLineText;

