import React from 'react';
import { connect } from 'react-redux';
import { AutoAffix } from 'react-overlays';
import './EditSurveyPage.css';
import {
    resetSaveTemplateRequest,
    saveTemplateRequest, saveTemplateRequestFail,
    saveTemplateRequestSuccess
} from "../../actions/login";
import {adminHome, userHome} from "../../reducers/session";
import Link from "react-router/es/Link";
import {Path} from "../../routes";
import TemplateFormModified from "../../components/TemplateFormModified";
import {getModifiedSurvey, getsurveyModified} from "../../reducers/survey";
import {bindActionCreators} from "redux";
import {fetchModifiedSurvey} from "../../actions/edit_modified_survey";
/*import {getSurvey} from "../reducers/edit_survey";*/
import {getSurvey} from "../../reducers/edit_survey";
class EditSurveyModifiedUserPage extends React.Component {
  loadData() {

    this.props.fetchModifiedSurvey(this.props.surveyId);
  }

  componentDidMount() {
    this.loadData();
  }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isSaveTemplateSuccess) {
            if(nextProps.currentUser.role === 'admin'){
                this.props.router.push(adminHome(nextProps.currentUser));
            }else{
                this.props.router.push(userHome(nextProps.currentUser));
            }
        }
    }

  /*componentWillReceiveProps(nextProps) {
      if (nextProps.isSaveTemplateSuccess) {
          this.props.router.push(Path.getUserModifiedSurvey(nextProps.survey));
      }
  }
*/

    componentDidUpdate(prevProps) {
    if (this.props.surveyId !== prevProps.surveyId) {
      this.loadData();
    }

  }

  render() {
      let { modifiedSurvey,surveyId} = this.props;
    return (
        <div className="EditSurveyPage container"  style={{marginTop:'50px'}}>
            <div className="col-xs-2 pull-right" style={{padding:'5px 0px'}}>
                <Link to={Path.editUserModifiedQuestions(surveyId)} >  <button className="btn btn-primary"  >Modify</button></Link>
            </div>
            <TemplateFormModified {...this.props}/>
        </div>
    );
  }
}


const mapStateToProps = (state,{ params }) => {
    return {
        surveyId: params.surveyId,
        modifiedSurvey: getSurvey(state.edit_survey),
        isSaveTemplateSuccess:state.templateSession.isSaveTemplateSuccess,
        currentUser: state.session.user
    }
};

const mapDispatchToProps = (dispatch,state) => {
    return {
        onSubmit: (values) => {
            return dispatch(saveTemplateRequest(values)).then(res => {
                dispatch(saveTemplateRequestSuccess(res));
                dispatch(resetSaveTemplateRequest(res))
                return Promise.resolve(res);
            }).catch(err => {
                dispatch(saveTemplateRequestFail(err));
                return Promise.reject(err);
            });
        },
        fetchModifiedSurvey: bindActionCreators(fetchModifiedSurvey, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSurveyModifiedUserPage);

