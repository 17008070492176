import  React from 'react';
import QuestionWrapper from './QuestionWrapper';
import {Field, FieldArray, Formik as setFieldValue, withFormik} from 'formik';
import './Survey.css';
// import this in the related component
import { useFormikContext } from 'formik';

type Props = {
    survey: object,
    isLoading: bool,
    error: object,
    onSubmit: func,
    onSubmitPdf:func,
    isSuccess: bool
};


const renderInput = ({field, form: { touched, errors }, ...props}) =>
    <div>
        <input {...field.input} {...field} {...props} className="form-control" />
        {
            touched[field.name] &&
            errors[field.name] &&
            <span className="help-block">{field.meta.error}</span>
        }
    </div>;

class Survey extends React.Component<Props> {

    constructor(props){
        super(props);
        this.state = {
            files: [],
            videos:[],
            question12: ''
        }

    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({favoritecolor: "yellow"})
        }, 1000)
    }

    componentDidUpdate(oldProps) {
        // By duplicating the data, you have to then

        if(oldProps.templateDemo.templateDemo !== this.props.templateDemo.templateDemo) {
            this.setState({
                files:this.props.templateDemo.templateDemo.images,
                videos:this.props.templateDemo.templateDemo.videos,
                question12: this.props.templateDemo.templateDemo.questions
            });
        }
    }


    fileSelectedHandler = async (e) => {
        try {
            await  this.setState({ files: [...this.state.files, ...e.target.files] });
            this.props.setFieldValue("files",this.state.files);

        } catch (error) {
            console.log(error);
        }

    }
    fileVideoHandler = async (e) => {

        try {
            await  this.setState({ videos: [...this.state.videos, ...e.target.files] });
            this.props.setFieldValue("videos",this.state.videos);
        } catch (error) {
            console.log(error);
        }
    }


    deleteImage = (file, index) => {
        const newlist = [].concat(this.state.files) // Clone array with concat or slice(0)
        newlist.splice(index, 1);
        this.setState({files: newlist});
        this.props.templateDemo.templateDemo.images.splice(index,1)
        this.props.setFieldValue("files",newlist);
    };

    deleteVideo = (file, index) => {
        const newlist = [].concat(this.state.videos) // Clone array with concat or slice(0)
        newlist.splice(index, 1);
        this.setState({videos: newlist});
        this.props.templateDemo.templateDemo.videos.splice(index,1)
        this.props.setFieldValue("videos",newlist);
    };

    renderError() {
        return <div>Error!</div>
    }

    renderLoading() {
        return <div>Loading...</div>
    }


    /*renderSuccess() {
        this.props.router.push('user/surveys');
    }*/
    onChangeValueHandler = (e,question,index) => {
        let items = [...this.state.question12];
        let item = {...this.state.question12[index]};
        item.answer = e.target.value;
        this.state.question12[index] = item;
        this.setState({items});
        this.props.setFieldValue('answer'+question._id,e.target.value);
    }

    render() {
        let { values, isLoading, error, handleSubmit } = this.props;
        let { templateDemo } = this.props.templateDemo;
        let { title, subTitle, questions,images,videos } = templateDemo;


        if (isLoading) {
            return this.renderLoading();
        }

        if (error) {
            return this.renderError();
        }


        let { isSubmitting, errors,survey,files } = this.props;
        return (
            <div className="Survey">
                {templateDemo  &&


                <form  onSubmit={values => {
                    if( this.props.values.isFirstButton){
                        handleSubmit(values,templateDemo)
                    } else {
                        handleSubmit(values,templateDemo)
                    }
                }} >
                    <legend>{templateDemo.title}</legend>
                    {errors.message && <div className="alert alert-danger" role="alert">{errors.message}</div>}
                    <div>
                        {this.state.question12.length >0 && this.state.question12.map((question, index) => (
                            <div key={index} style={{marginTop:"20px"}}>
                                <label >{question.title}</label>
                                <Field  style={{width:'100%'}}
                                        onChange={e => {this.onChangeValueHandler(e,question,index)}}
                                        name={'answer'+question._id} value={question.answer}
                                        component={question.type=== 'MUTLI_LINE_TEXT'? 'textarea':renderInput}
                                        placeholder={question.placeholder}
                                        type={question.type=== 'MUTLI_LINE_TEXT'? 'textarea':'text'}/>
                               {/* {question.type ===
                                <Field type="text"  onChange={e => {this.onChangeValueHandler(e,question,index)}} className="form-control"  name={'answer'+question._id} value={question.answer}   component='input'/>
                                }*/}


                            </div>
                        ))}
                    </div>
                    <div className="col-lg-12" style={{padding:20}}>
                        <div className="my-3">
                            <h4>Please add some images(Multiple images one by one)</h4>
                            {/*<label htmlFor="files">Select file</label>*/}
                            <input type="file" name="files" multiple onChange={this.fileSelectedHandler}  />
                            { this.state.files && this.state.files.length > 0  && this.state.files.map((file, index) => {
                                return (
                                    <div className="col-md-6" style={{padding:20}}>
                                        {file &&
                                        <div>
                                            <span className="glyphicon glyphicon-remove" aria-hidden="true" onClick={()=>this.deleteImage(file, index)}  />
                                            <img className="img-responsive" src={file['Location'] ? file['Location'] : URL.createObjectURL(file)}   />
                                        </div>

                                        }

                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
                    <div className="col-lg-12" style={{padding:20}}>
                        <div className="my-3">
                            <h4>Please add some videos(Multiple videos one by one mp4 format)</h4>
                            {/*  <label htmlFor="videos">Select file</label>*/}
                            <input type="file" name="videos" multiple onChange={this.fileVideoHandler}  />
                            { this.state.videos && this.state.videos.length > 0  && this.state.videos.map((file, index) => {
                                return (
                                    <div className="col-md-6" style={{padding:20}}>

                                        {/* <video className="img-responsive" src={URL.createObjectURL(file)}   />*/}
                                        {file &&
                                        <div>
                                            <span className="glyphicon glyphicon-remove" aria-hidden="true" onClick={()=>this.deleteVideo(file, index)}  />
                                            <video width="320" height="240" controls>
                                                <source src={file['Location'] ? file['Location'] : URL.createObjectURL(file)}  type="video/mp4"/>
                                            </video>
                                        </div>
                                        }

                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={(e)=>{
                            this.props.values.isFirstButton = 'save-button'
                            /*handleSubmit(e);*/
                        }}>Save
                    </button>
                    <div className="row" style={{marginTop:'20px'}}>
                        <div className="col-md-6">
                            <div className="form-group">
                                <button type="submit" onClick={(e)=>{
                                    this.props.values.isFirstButton = 'delete-button'
                                    /*handleSubmit(e);*/
                                }} className="btn btn-primary">Delete</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <button onClick={(e)=>{
                                    this.props.values.isFirstButton = 'convert-button'
                                    /*handleSubmit(e);*/
                                }}  className="btn btn-primary">Convert</button>
                            </div>
                        </div>
                    </div>
                    {/* {isSubmitting ?
                  <button className="btn btn-primary" type="submit" disabled>Loading...</button> :
                  <button className="btn btn-primary" type="submit">Update Template</button>}*/}
                </form>

                }
            </div>
        );
    }
}



export default withFormik({
    enableReinitialize: true,

    mapPropsToValues: (props,values) => (
        {
            isFirstButton: '',
        }),
    handleSubmit: ( values,{props}) => {



        if(values.isFirstButton === 'save-button'){
            props.templateDemo.templateDemo.questions.map((currElement, index) =>  {
                if(!values['answer'+currElement._id]===""){
                    currElement['answer']=values['answer'+currElement._id]
                }
            });
            /*let newTemplate = props.survey.question_order.map(id =>  props.survey.questions[id]);

            let newTemplate2=newTemplate.map((currElement, index) =>  currElement['answer']=values['answer'+index]);*/

            let newTemplate3={};
            let images=[];
            let videos=[];
            let filteredFiles=[];
            let filteredVideos=[];

             if( props.templateDemo.templateDemo.images.length > 0){
                 images=props.templateDemo.templateDemo.images;
            }
            if( props.templateDemo.templateDemo.videos.length > 0){
                videos=props.templateDemo.templateDemo.videos;
            }

            if(values.files && values.files.length > 0){
                filteredFiles=values.files.filter(file => file['name'] && file['type'])
               /* images =values.files;*/
              /*  console.log('props.templateDemo.templateDemo.images',props.templateDemo.templateDemo.images)*/

                props.templateDemo.templateDemo.images.concat(values.files);

            }
            if(values.videos && values.videos.length > 0){
                filteredVideos=values.videos.filter(video => video['name'] && video['type']);
                props.templateDemo.templateDemo.videos.concat(values.videos);
               /* videos.push(values.videos);*/
            }


            if(values.files && values.files.length>0){
                newTemplate3 = values.files.filter(file => !file._id && !file['Location']).map(file => {
                    /*if(!file.name && !file.size){
                        return file
                    }*/
                    return new Promise((resolve, reject) => {
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {

                            resolve(reader.result);
                        };
                    })
                });
            }

            let newTemplate4={};
            if(values.videos && values.videos.length>0){
                newTemplate4 = values.videos.filter(file => !file._id && !file['Location']).map(video => {
                    /*if(!video.name && !video.size){
                        return video
                    }*/
                    return new Promise((resolve, reject) => {
                        var reader = new FileReader();
                        reader.readAsDataURL(video);
                        reader.onload = function () {

                            resolve(reader.result);
                        };
                    })
                });
            }


            if(filteredFiles.length > 0 || filteredVideos.length > 0){

                let totalArray=[newTemplate3,newTemplate4]
                if(!(newTemplate3.length > 0)){
                    totalArray=[newTemplate4]
                }
                if(!(newTemplate4.length > 0)){
                    totalArray=[newTemplate3]
                }

                if(newTemplate3 && newTemplate4){
                    const promise4all = Promise.all(
                        totalArray.map(function(innerPromiseArray) {
                            return Promise.all(innerPromiseArray);
                        })
                    );

                    promise4all.then(function(results) {

                        let type;
                        let exactType;
                        if(results.length === 1){
                            type = results[0].toString().split(';')[0].split(':')[1];
                            exactType = type.toString().split('/')[0];
                        }

                        let imageArray=[];
                        let videoArray=[];



                        if(filteredFiles.length > 0){
                            imageArray=results[0];
                        }
                        if(filteredVideos.length > 0){
                            videoArray=results[1];
                        }
                        if(exactType === 'image'){
                            imageArray=results[0];
                        }else if(exactType === 'video'){
                            videoArray=results[0];
                        }


                        let appendRemovedImages=images.filter(file => file._id && file['Location']);
                        let appendRemovedVideos=videos.filter(file => file._id && file['Location']);
                        if(!appendRemovedImages){
                            appendRemovedImages=images;
                        }
                        if(!appendRemovedVideos){
                            appendRemovedVideos=videos;
                        }


                        props.templateDemo.templateDemo['images']= appendRemovedImages.concat(imageArray);
                        props.templateDemo.templateDemo['videos']=appendRemovedVideos.concat(videoArray);
                        props.onSubmitSave(props.templateDemo.templateDemo);
                    })
                }

            }else{

                props.templateDemo.templateDemo['images']=images;
                props.templateDemo.templateDemo['videos']=videos;
                props.onSubmitSave(props.templateDemo.templateDemo);

            }

        }
        else if(values.isFirstButton === 'delete-button'){
            props.onSubmit(props.surveyId,props.currentUser);
        }else if(values.isFirstButton === 'convert-button'){
            props.onSubmitPdf(props.templateDemo,props.currentUser.id)
        }
    }
})(Survey);
