import * as React from 'react';
import {Field, Formik as setFieldValue, Formik as setFieldTouched, withFormik} from 'formik';
import Select from 'react-select';

const renderInput = ({field, form: { touched, errors }, ...props}) =>
    <div>
      <input {...field.input} {...field} {...props} className="form-control" />
      {
        errors[field.name] &&
        <span className="text-danger help-block">{errors[field.name].join(" ")}</span>
      }
    </div>;

type Props = {
  onSubmit: func
};

class RegisterForm extends React.Component<Props> {


    constructor(props) {
        super(props);
        this.state = {
            selected: '',
            selectOptions : [{name:'primary-group'},{name:'secondary-group'}],
            id: "",
            name: ''
        }
       /* const  = [

        ]*/

    }

    componentDidMount() {
        this.state = {
            username:'demo',
            password:'1234',
            passwordConfirm:'1234',

        };
    }

    /*handleChange(e){
        this.setState({ name:e.name})
    }*/

    /*handleChange = e => {
        const selected = e.target.value; // selected name
        switch(selected) {
            // change class
        }
        this.setState({
            selected
        });
    }*/
  render() {
    let {values,
        isSubmitting, handleSubmit, email, password,passwordConfirm,options,handleChange,
        handleBlur,errors
    } = this.props;
    return (
       <div>
        <form onSubmit={handleSubmit}>
          <legend>Register</legend>
          {errors.message && <div className="alert alert-danger" role="alert">{errors.message}</div>}
          <div className="form-group">
            <label htmlFor="">Email</label>
            <Field value={email}
                name="email"
                component={renderInput}
                type="email"/>
          </div>
          <div className="form-group">
            <label htmlFor="">Password</label>
            <Field value={password}
                name="password"
                component={renderInput}
                type="password"/>
          </div>
          <div className="form-group">
            <label htmlFor="">Confirm Password</label>
            <Field  name="passwordConfirm"
                value={passwordConfirm}
                component={renderInput}
                type="password"/>
          </div>
            <div className="form-group">
                <select className="form-control"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ display: 'block' }}
                >
                    <option value="" label="Select a group" />
                    <option value="group-primary" label="group-primary" />
                    <option value="group-secondary" label="group-secondary" />
                </select>
            </div>
          {isSubmitting ?
              <button className="btn btn-primary" type="submit" disabled>Loading...</button> :
              <button className="btn btn-primary" type="submit">Submit</button>}
        </form>
       </div>
    );
  }
}

export default withFormik({
    mapPropsToValues({ username,color }) {
        return {
            username: '',
            password: '',
            passwordConfirm: '',
            role:''
        };
    },
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        props.onSubmit(values).then((data) => {
            setSubmitting(false);
        }, (err) => {
            setSubmitting(false);
            setErrors({
                message: err.response.data.message
            })
        })
    }
})(RegisterForm);
