import React from 'react';
import SurveyList from '../../containers/SurveyList';
import './UserSurveysPage.css';
import UserSurveyList from "../../components/UserFilledTable/UserSurveyList";
import UserModfiedSurveyList from "../../modified/usermodified/UserSurveyList";
import SecondaryUserSurveyList from "../../components/SecondaryUserFilledTable/SecondaryUserSurveyList";

class UserSurveysPage extends React.Component {

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return null;
        };
    }
      render() {
    return (
       <div>
            <div className="container UserSurveysPage" style={{backgroundColor: '#e1e8ff',padding: '25px'}}>
              <h4>Templates Provided By Admin</h4>
              <SurveyList/>
            </div>
           <div className="container UserSurveysPage" style={{backgroundColor: '#ff8a73',padding: '25px'}} >
               <div className="TableReport">
                   <h4>Modified Templates</h4>
                   <UserModfiedSurveyList/>
               </div>
           </div>
            <div className="container UserSurveysPage" style={{backgroundColor: '#99e599',padding: '25px'}} >
                <div className="TableReport">
                    <h4>Saved Templates</h4>
                    <UserSurveyList/>
                </div>
            </div>
           <div className="container UserSurveysPage" style={{backgroundColor: '#C133FF',padding: '25px'}} >
               <div className="TableReport">
                   <h4>Other User Templates</h4>
                   <SecondaryUserSurveyList/>
               </div>
           </div>
       </div>
    );
  }
}

export default UserSurveysPage;