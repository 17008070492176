import { combineReducers } from 'redux';

import register from './register';
import templateSession from './templateSession';
import session from './session';
import surveys from './surveys';
import userSurveys from './userSurveys';
import userModifiedSurveys from './userModifiedSurveys';
import userAdminSurveys from './userAdminSurveys';
import create_survey from './create_survey';
import edit_survey from './edit_survey';
import survey, {activateTemplateReducer, fetchSecondaryDisabledReducer} from './survey';
import data from './data/index';
import  forget from  './forget-password';
import  reset from  './reset-password';
import  secondaryUserSurveys from  './secondaryUserSurveys';

const root = combineReducers({
  register,
  session,
  surveys,
  forget,
  create_survey,
  edit_survey,
  survey,
  data,
  templateSession,
  userSurveys,
  reset,
  secondaryUserSurveys,
  userAdminSurveys,
  userModifiedSurveys
});


export default root;


