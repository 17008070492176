import React from 'react';
import { Route } from 'react-router';
import App from './App';
import RegisterPage from './pages/auth/RegisterPage';
import LoginPage from './pages/auth/LoginPage';
import UserSurveysPage from './pages/user/UserSurveysPage';
import LogoutPage from './pages/auth/LogoutPage';
import EditSurveyPage from './pages/user/EditSurveyPage';
import EditSurveyUserPage from './pages/user/EditSurveyUserPage';
import UsersPage from './pages/admin/UsersPage';
import SurveyPage from './pages/user/SurveyPage';
import { fetchCurrentUserRequest, fetchCurrentUserRequestSuccess, fetchCurrentUserRequestFail } from './actions/login';
import {adminHome, secondaryUserHome, userHome} from "./reducers/session";
import AdminSurveyPage from "./pages/admin/AdminSurveyPage";
import DisabledSurveyPage from "./pages/secondary-user/DisabledSurveyPage";
import ForgetPasswordPage from "./pages/auth/ForgetPasswordPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import ActivationPage from "./pages/auth/ActivationPage";
import SecondarySurveyPage from "./pages/secondary-user/SecondarySurveyPage";
import DisabledSecondarySurveyPage from "./pages/secondary-user/DisabledSecondarySurveyPage";
import UserTemplateTablePage from "./pages/admin/UserTemplateTablePage";
import EditSurveyPage2 from "./pages/user/EditSurveyPage2";
import EditSurveyModifiedUserPage from "./pages/user/EditSurveyModifiedUserPage";
import EditUserModifedSurveyPage from "./pages/user/EditUserModifedSurveyPage";
import DisbaledEditUserSurveyPage from "./pages/user/DisbaledEditUserSurveyPage";

const skipAuthPaths = ['/login', '/register', '/logout','/reset-password','/forget-password'];

export default function routes(store) {
  function requireAuth(nextState, replace, next) {
    const state = store.getState();
    const nextPath = nextState.location.pathname;
     if(nextState.location.pathname.split('/')[1] === 'reset-password' || nextState.location.pathname.split('/')[1] ===  'get-template'){
         next();
     }
    if (!state.session.user && skipAuthPaths.indexOf(nextPath) === -1) {
        store.dispatch(fetchCurrentUserRequest()).then(res => {
        store.dispatch(fetchCurrentUserRequestSuccess(res));
        dispatchHomePage(nextState, replace, store);
        next();
      }, err => {
        store.dispatch(fetchCurrentUserRequestFail(nextPath));
        replace('/login');
        next();
      });
    } else {
      dispatchHomePage(nextState, replace, store);
      next();
    }
  }

  function dispatchHomePage(nextState, replace, store) {
    const state = store.getState();
    if (state.session.user && nextState.location.pathname === '/') {

      if(state.session.user.role === 'admin'){
          replace({
              pathname: adminHome(state.session.user)
          });
      }else if(state.session.user.role === 'group-secondary'){
            replace({
                pathname: secondaryUserHome(state.session.user)
            });
      } else{

            replace({
                pathname: userHome(state.session.user)
            });
        }
    }
  }

  return (
      <Route path="/" component={App} onEnter={requireAuth} >
        <Route path="register" component={RegisterPage}/>
        <Route path="forget-password" component={ForgetPasswordPage}/>
        <Route path="reset-password/:email/:key" component={ResetPasswordPage}/>
        <Route path="get-template/:email/:templateId" component={ActivationPage}/>
        <Route path="login" component={LoginPage}/>
        <Route path="logout" component={LogoutPage}/>
        <Route path="user/surveys" component={UserSurveysPage}  />
        <Route path="secondary-user/surveys" component={SecondarySurveyPage}  />
        <Route path="admin/surveys" component={AdminSurveyPage } />
        <Route path="admin/users" component={UsersPage} />
        <Route path="admin/:userId/getAllUserSurveysForAdmin" component={UserTemplateTablePage} />
        <Route path="surveys/:surveyId" component={SurveyPage}  />
        <Route path="user/surveys/:surveyId/"   >
          <Route path="edit" component={EditSurveyPage}  />
          <Route path="editUser" component={EditSurveyUserPage} />
          <Route path="editUserQuestions" component={EditSurveyPage2}  />
          <Route path="editUserModfied" component={EditSurveyModifiedUserPage} />
          <Route path="editUserModifiedQuestions" component={EditUserModifedSurveyPage}  />
          <Route path="disabledUser" component={DisabledSurveyPage} />
          <Route path="disabledUserQuestions" component={DisbaledEditUserSurveyPage}  />
          <Route path="disabledSecondaryUser" component={DisabledSecondarySurveyPage} />
        </Route>
      </Route>
  );
}

export const Path = {
  survey(survey) {
    return `/user/surveys/${survey._id}/edit`;
  },

  primarySurvey(survey) {
      return `/user/surveys/${survey._id}/editUser`;
  },
    editAdminSurvey(survey) {
        return `/user/surveys/${survey._id}/edit`;
    },

    getUserAllTemplates(userId) {
        return `/admin/${userId}/getAllUserSurveysForAdmin`;
    },
    getUserModifiedSurvey(survey) {
        return `/user/surveys/${survey._id}/editUserModfied`;
    },

    getUserModifiedSavedTemplate(survey) {
        return `/user/surveys/${survey._id}/disabledUser`;
    },

  disabledUserSurvey(survey) {
      return `/user/surveys/${survey._id}/disabledUser`;
  },
    disabledSecondaryUser(survey) {
        return `/user/surveys/${survey._id}/disabledSecondaryUser`;
    },
    editUserQuestions(surveyId) {
        return `/user/surveys/${surveyId}/editUserQuestions`;
    },
    editDisbaledUserQuestions(surveyId) {
        return `/user/surveys/${surveyId}/disabledUserQuestions`;
    },
    editUserModifiedQuestions(surveyId) {
        return `/user/surveys/${surveyId}/editUserModifiedQuestions`;
    },
  editSurvey(survey) {
    return `/user/surveys/${survey.id}/edit`;
  },

  surveyList() {
    return `/user/surveys`;
  },

    adminPage() {
        return `/admin/surveys`;
    },

    userPage() {
        return `/user/surveys`;
    },

    secondaryUserPage() {
        return `/secondary-user/surveys`;
    },

    viewSurvey(survey) {
    return `/surveys/${survey.id}`;
  },

  login() {
    return `/login`;
  },

  logout() {
    return `/logout`;
  }
};
