import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserModifiedSurveyListView from './UserSurveyListView';
import {fetchUserModifiedSurveysRequest, fetchUserSurveysRequest} from '../../actions/userSurveys';

class   UserModfiedSurveyList extends React.Component {
    loadData() {
        this.props.fetchUserModifiedSurveys(this.props.currentUser);
    }

    componentWillMount() {
        this.loadData();
    }

    renderLoading() {
        return <div>Loading...</div>
    }

    renderError() {
        return <div>Load Error</div>
    }

    render() {
        const { userModifiedSurveys, isLoading, loadError } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        if (loadError) {
            return this.renderError();
        }

        return (
           <div>
                <UserModifiedSurveyListView userModifiedSurveys={userModifiedSurveys}/>
           </div>

        );
    }
}

const mapStateToProps = (state) => (
    {
    userModifiedSurveys: state.userModifiedSurveys.userModifiedSurveys,
    isLoading: state.surveys.isLoading,
    loadError: state.surveys.error,
    currentUser: state.session.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserModifiedSurveys: bindActionCreators(fetchUserModifiedSurveysRequest, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserModfiedSurveyList);