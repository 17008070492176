import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {fetchUserSurveysForAdminRequest, fetchUserSurveysRequest} from "../../actions/userSurveys";
import userAdminSurveys from "../../reducers/userAdminSurveys";
import EmailAdmin from "../../components/EmailAdmin";
import Email from "../../components/Survey/Email";
import {sendTemplateLink, sendTemplateToUser} from "../../actions/survey";
import {getSubmitEmailStatus} from "../../reducers/survey";
import {submitEmailTemplate} from "../../reducers/survey";


class UserTemplateTablePage extends React.Component {

    loadData() {
        this.props.fetchUserSurveysForAdminRequest(this.props.userId);
    }

    componentWillMount() {
        this.loadData();
    }

    renderLoading() {
        return <div>Loading...</div>
    }

    renderError() {
        return <div>Load Error</div>
    }


    render(){
        const {userSurveys} = this.props
        return (
            <div className="container">
                <div  className="container h-100 w-100  text-left">
                    <h1 className="text-center">Number of Templates</h1>
                    <div className="row mt-5">
                        <div className="col-xs-12 table-responsive"   id="page-content-wrapper">
                            {this.props.userSurveys.length>0?<table  className="table table-striped header-fixed header-fixed2" >
                                <thead>
                                <tr>
                                    <th>Template Name</th>
                                    <th>Template Id</th>
                                    <th>Send Email</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userSurveys && userSurveys.map((template) => {
                                    return (
                                        <tr key={template._id}>
                                            <td>{template.title} </td>
                                            <td>{template._id}</td>
                                            <td>
                                                {this.props.userSurveys && this.props.userSurveys.length > 0 &&
                                                 <EmailAdmin {...template} {...this.props} />
                                                }
                                                </td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </table>:<h5>No templates For Current User</h5>

                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state,{ params }) => (
    {
        userSurveys: state.userAdminSurveys.userSurveys,
        loadError: state.surveys.error,
        currentUser: state.session.user,
        userId:params.userId,
    });

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserSurveysForAdminRequest: bindActionCreators(fetchUserSurveysForAdminRequest, dispatch),
        onSubmitEmail: bindActionCreators(sendTemplateLink, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTemplateTablePage);