// @flow

import * as React from "react";
import {Field, withFormik} from 'formik';

type Props = {
  _id: string,
  title: string,
  placeholder: string
};

class MultiLineText extends React.Component<Props> {
  render() {
    const {question } = this.props;

    const { title, placeholder, _id}=question;
    return (
        <div>
            <h3 className="question-title">{title}</h3>
            { question.answer && question.answer === "" ?
                (
                    <div className="form-group">
                        <Field type="text" className="form-control" value={placeholder} placeholder={placeholder} name={_id} component='textarea' />
                    </div>
                ):(
                    <div className="form-group">
                        <Field type="text" className="form-control"   name={'answer'+_id}  value={question.answer}  component='textarea'/>
                    </div>
                )

            }
           {/* { this.props.answer && this.props.answer.trim() === "" ?
                (
                    <div className="form-group">
                        <Field component="textarea" className="form-control" placeholder={placeholder} name={_id}/>
                    </div>
                ):(
                    <div className="form-group">
                        <Field  className="form-control"  onChange={e => {
                            this.props.setFieldValue('answer'+_id, e.target.value);
                        }}  name={'answer'+_id}  component='textarea' />
                    </div>
                )
*/}


        </div>
    );
  }
}
/*mapPropsToValues: props => ({

    isFirstButton: false
}),

export default ;*/
export default withFormik({
    enableReinitialize: true,

    mapPropsToValues: props => ({
        ['answer'+props._id]:props.question.answer
    }),

})(MultiLineText);