import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert } from 'react-bootstrap';
import Survey from '../../components/Survey/Survey';
import {
    getSurvey,
    getFetchError,
    getFetchStatus,
    getSubmitStatus,
    getTemplateData,
    getSecondaryTemplateData
} from '../../reducers/survey';
import {
    fetchDisabledSurvey,
    deleteUserResult,
    convertToPDF,
    sendTemplateToUser,
    fetchSecondaryDisabledSurvey
} from '../../actions/survey';
import '../user/SurveyPage.css';
import FileSaver from 'file-saver'
import Email from "../../components/Survey/EmailSecondary";
import SecondarySurvey from "../../components/Survey/SecondarySurvey";

class DisabledSecondarySurveyPage extends React.Component {
  loadData() {
    this.props.fetchSecondaryDisabledSurvey(this.props.surveyId);
  }

  componentDidMount() {
    this.loadData();
  }

  constructor(props) {
      super(props);
      this.state = { templateSecondaryDemo: {} }
  }

    componentWillReceiveProps(newProps){
        this.setState({
            templateSecondaryDemo: newProps.templateSecondaryDemo
        })
    }

  render() {
    return (
        <div className="container" style={{marginBottom:'40px'}}>
          {this.props.templateSecondaryDemo ? '' : <Alert bsStyle="danger">No template Found</Alert>}
          <div className="row SurveyPage">
            <div className="col-md-8 col-md-offset-2 survey">
                {this.props.templateSecondaryDemo &&
                <SecondarySurvey {...this.props}/>
                }
            </div>
              <div className="col-md-8 col-md-offset-2 survey">
                  {this.props.templateSecondaryDemo &&
                  <Email {...this.props}/>
                  }
              </div>

          </div>
        </div>
    )
  }
}

const mapStateToProps = (state, { params }) => {
  return {
    survey: getSurvey(state.survey),
    isLoading: getFetchStatus(state.survey),
    error: getFetchError(state.survey),
    surveyId: params.surveyId,
    isSuccess: getSubmitStatus(state.survey),
    templateSecondaryDemo:getSecondaryTemplateData(state.survey),
    currentUser: state.session.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitEmail: bindActionCreators(sendTemplateToUser, dispatch),
    onSubmit: bindActionCreators(deleteUserResult, dispatch),
    fetchSecondaryDisabledSurvey: bindActionCreators(fetchSecondaryDisabledSurvey, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisabledSecondarySurveyPage);
