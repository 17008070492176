import * as api from '../api';
import initSurvey from '../constants/InitSurvey';

export const fetchUserSurveysRequest = user => dispatch => {
    dispatch({
        type: 'FETCH_USER_SURVEYS_REQUEST',
        payload: user
    });

    return api.fetchUserPersonalSurveys(user).then(res => {
        dispatch({
            type: 'FETCH_USER_SURVEYS_REQUEST_SUCCESS',
            payload: res
        });
    }).catch(err => {
        dispatch({
            type: 'FETCH_USER_SURVEYS_REQUEST_FAIL',
            payload: err
        });
    })
};

export const fetchUserModifiedSurveysRequest = user => dispatch => {
    dispatch({
        type: 'FETCH_USER_MODIFIED_SURVEYS_REQUEST',
        payload: user
    });

    return api.fetchUserModifiedPersonalSurveys(user).then(res => {
        dispatch({
            type: 'FETCH_USER_MODIFIED_SURVEYS_REQUEST_SUCCESS',
            payload: res
        });
    }).catch(err => {
        dispatch({
            type: 'FETCH_USER_MODIFIED_SURVEYS_REQUEST_FAIL',
            payload: err
        });
    })
};

export const fetchUserSurveysForAdminRequest = userId => dispatch => {
    dispatch({
        type: 'FETCH_USER_ADMIN_SURVEYS_REQUEST',
        payload: userId
    });

    return api.fetchUserAdminPersonalSurveys(userId).then(res => {
        dispatch({
            type: 'FETCH_USER_ADMIN_SURVEYS_REQUEST_SUCCESS',
            payload: res
        });
    }).catch(err => {
        dispatch({
            type: 'FETCH_USER_ADMIN_SURVEYS_REQUEST_FAIL',
            payload: err
        });
    })
};


export const fetchSecondaryUserSurveysRequest = user => dispatch => {
    dispatch({
        type: 'FETCH_SECONDARY_USER_SURVEYS_REQUEST',
        payload: user
    });

    return api.fetchSecondaryUserSurveys(user).then(res => {
        dispatch({
            type: 'FETCH_SECONDARY_USER_SURVEYS_REQUEST_SUCCESS',
            payload: res
        });
    }).catch(err => {
        dispatch({
            type: 'FETCH_SECONDARY_USER_SURVEYS_REQUEST_FAIL',
            payload: err
        });
    })
};


export const createUserPersonalSurveyRequestSuccess = (res) => ({
    type: 'CREATE_USER_SURVEY_REQUEST_SUCCESS',
    payload: res
});

export const createUserPersonalSurveyRequestFail = (err) => ({
    type: 'CREATE_USER_SURVEY_REQUEST_FAIL',
    payload: err
});

export const createUserSurveyRequest = (userId) => dispatch => {
    dispatch({
        type: 'CREATE_USER_SURVEY_REQUEST',
        userId,
        payload: initSurvey
    });
    return api.createSurvey(userId, initSurvey)
        .then(res => dispatch(createUserPersonalSurveyRequestSuccess(res)))
        .catch(err => dispatch(createUserPersonalSurveyRequestFail(err)));
};


export const resetCreateSurvey = () => ({
    type: 'RESET_USER_CREATE_SURVEY'
});
