import React from 'react';
import SurveyList from '../../containers/SurveyList';
import '../user/UserSurveysPage.css';
import SecondaryUserSurveyList from "../../components/SecondaryUserFilledTable/SecondaryUserSurveyList";

class SecondaryUserPage extends React.Component {
    render() {
        return (
            <div>
                <div className="container UserSurveysPage">
                    <div className="TableReport">
                        <h4>Filled Up Template By All Users</h4>
                        <SecondaryUserSurveyList/>
                    </div>
                </div>
            </div>
        );
    }
}

export default SecondaryUserPage;