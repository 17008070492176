import React from 'react';
import { Link } from 'react-router';
import { Path } from '../../routes';
import './../../components/UserFilledTable/SurveyItem.css';
import {connect} from "react-redux";
import deleteIcon from "../../src_assets/images/delete.png";
import {bindActionCreators} from "redux";
import {deleteSurveyModified} from "../../actions/edit_survey";

class UserModifiedSurveyItem extends React.Component {

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isSuccess) {
            this.props.resetCreateSurvey();
            this.props.router.push(Path.editSurvey(this.props.newSurvey));
        }
    }
    render() {
        let {userModifiedSurvey, userModifiedSurvey: { title } ,currentUser,deleteSurveyModified} = this.props;
        return (
            <div className="SurveyItem">
                <Link style={{cursor:'pointer'}} className='image-delete-icon' onClick={() => {
                    deleteSurveyModified(userModifiedSurvey._id,currentUser);
                }} > <img  src={deleteIcon}  height="20" width="20" />
                </Link>
                <Link to={Path.getUserModifiedSurvey(userModifiedSurvey)}>{title}</Link>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.session.user
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        deleteSurveyModified: bindActionCreators(deleteSurveyModified, dispatch)
    }

};

UserModifiedSurveyItem.propTypes = {};
UserModifiedSurveyItem.defaultProps = {};
export default connect(mapStateToProps,mapDispatchToProps)(UserModifiedSurveyItem);

