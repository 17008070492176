import * as Survey  from '../reducers/survey'
import * as api from '../api';
import {FETCH_DISABLED_USER_SURVEY_REQUEST_FAIL} from "../reducers/survey";
import {FETCH_DISABLED_USER_SURVEY_REQUEST_SUCCESS} from "../reducers/survey";
import {FETCH_DISABLED_USER_SURVEY_REQUEST} from "../reducers/survey";
import {FETCH_USER_REQUEST, FETCH_USER_REQUEST_FAIL, FETCH_USER_REQUEST_SUCCESS} from "../reducers/data";
import {result} from "lodash";
import {FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST} from "../reducers/survey";
import {FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_SUCCESS} from "../reducers/survey";
import {FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_FAIL} from "../reducers/survey";
import {fetchSurveysRequest} from "./surveys";
import {fetchUserSurveysRequest} from "./userSurveys";
import {SUBMIT_SURVEY_COMPLETED} from "../reducers/survey";
import {toast} from "react-toastify";

export const fetchSurvey = surveyId => dispatch => {
  dispatch({
    type: Survey.FETCH_SURVEY_REQUEST,
    surveyId
  });

  api.fetchSurvey(surveyId).then(res => {
    dispatch({
      type: Survey.FETCH_SURVEY_REQUEST_SUCCESS,
      payload: res
    });
  }).catch(err => {
    dispatch({
      type: Survey.FETCH_SURVEY_REQUEST_FAIL,
      payload: err
    });
  });
};


export const fetchDisabledSurvey = (surveyId,userId) => dispatch => {
    dispatch({
        type: FETCH_DISABLED_USER_SURVEY_REQUEST,
        surveyId,
        userId
    });

    api.fetchDisabledSurvey(surveyId,userId).then(res => {
        dispatch({
            type: FETCH_DISABLED_USER_SURVEY_REQUEST_SUCCESS,
            payload: res
        });
    }).catch(err => {
        dispatch({
            type: FETCH_DISABLED_USER_SURVEY_REQUEST_FAIL,
            payload: err
        });
    });
};
export const fetchSecondaryDisabledSurvey = (surveyId) => dispatch => {
    dispatch({
        type: FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST,
        surveyId
    });

    api.fetchDisabledSecondarySurvey(surveyId).then(res => {
        dispatch({
            type: FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_SUCCESS,
            payload: res
        });
    }).catch(err => {
        dispatch({
            type: FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_FAIL,
            payload: err
        });
    });
};


export const submitResult = (surveyId, result) => dispatch => {
    dispatch({
        type: Survey.SUBMIT_SURVEY,
        surveyId,
        payload: result
    });

    return api.deleteUserTemplate(surveyId, result).then(() => {
        dispatch({
            type: Survey.SUBMIT_SURVEY_SUCCESS
        });
    }).catch((err) => {
        dispatch({
            type: Survey.SUBMIT_SURVEY_FAIL,
            payload: err
        });
    });
};

export const deleteUserResult = (surveyId,user) => dispatch => {
  dispatch({
    type: Survey.SUBMIT_SURVEY,
    surveyId,
  });

  return api.deleteUserTemplate(surveyId, user.id).then(() => {
    /*dispatch(fetchUserSurveysRequest(user));*/
    dispatch({
      type: Survey.SUBMIT_SURVEY_SUCCESS
    });
      dispatch({
          type: Survey.SUBMIT_SURVEY_COMPLETED
      });

  }).catch((err) => {
    dispatch({
      type: Survey.SUBMIT_SURVEY_FAIL,
      payload: err
    });
  });
};


export const convertToPDF = (template) => dispatch => {
    dispatch({
        type: Survey.SUBMIT_PDF_SURVEY,
        payload: result
    });

    return api.convertToPdf(template).then(() => {
        dispatch({
            type: Survey.SUBMIT_PDF_SURVEY_SUCCESS
        });
    }).catch((err) => {
        dispatch({
            type: Survey.SUBMIT_PDF_SURVEY_FAIL,
            payload: err
        });
    });
};


export const sendTemplateToUser = (userInfo) => dispatch => {
    dispatch({
        type: Survey.SUBMIT_TEMPLATE_EMAIL_REQUEST,
        payload: result
    });

    return api.sendTemplateToUser(userInfo).then((values) => {
        dispatch({
            type: Survey.SUBMIT_TEMPLATE_EMAIL_SUCCESS
        });
        toast.success(values.message);
    }).catch((err) => {
        dispatch({
            type: Survey.SUBMIT_TEMPLATE_EMAIL_FAIL,
            payload: err
        });
    });
};

export const sendTemplateLink = (userInfo) => dispatch => {
    dispatch({
        type: Survey.SUBMIT_TEMPLATE_EMAIL_REQUEST,
        payload: result
    });

    return api.sendTemplateLink(userInfo).then((values) => {
        dispatch({
            type: Survey.SUBMIT_TEMPLATE_EMAIL_SUCCESS
        });
        toast.success(values.message);
    }).catch((err) => {
        dispatch({
            type: Survey.SUBMIT_TEMPLATE_EMAIL_FAIL,
            payload: err
        });
    });
};
