import { combineReducers } from 'redux';



export const SUBMIT_SURVEY = 'SUBMIT_SURVEY';
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
export const SUBMIT_SURVEY_COMPLETED = 'SUBMIT_SURVEY_COMPLETED';
export const SUBMIT_SURVEY_FAIL = 'SUBMIT_SURVEY_FAIL';


export const SUBMIT_PDF_SURVEY = 'SUBMIT_PDF_SURVEY';
export const SUBMIT_PDF_SURVEY_SUCCESS = 'SUBMIT_PDF_SURVEY_SUCCESS';
export const SUBMIT_PDF_SURVEY_FAIL = 'SUBMIT_PDF_SURVEY_FAIL';

export const FETCH_SURVEY_REQUEST = 'SURVEY_FETCH_SURVEY_REQUEST';
export const FETCH_SURVEY_REQUEST_SUCCESS = 'SURVEY_FETCH_SURVEY_REQUEST_SUCCESS';
export const FETCH_SURVEY_REQUEST_FAIL = 'SURVEY_FETCH_SURVEY_REQUEST_FAIL';

/*Modified SUrvey*/

export const FETCH_MODIFIED_SURVEY_REQUEST = 'FETCH_MODIFIED_SURVEY_REQUEST';
export const FETCH_MODIFIED_SURVEY_REQUEST_SUCCESS = 'FETCH_MODIFIED_SURVEY_REQUEST_SUCCESS';
export const FETCH_MODIFIED_SURVEY_REQUEST_FAIL = 'FETCH_MODIFIED_SURVEY_REQUEST_FAIL';


export const FETCH_DISABLED_USER_SURVEY_REQUEST = 'FETCH_DISABLED_USER_SURVEY_REQUEST';
export const FETCH_DISABLED_USER_SURVEY_REQUEST_SUCCESS = 'FETCH_DISABLED_USER_SURVEY_REQUEST_SUCCESS';
export const FETCH_DISABLED_USER_SURVEY_REQUEST_FAIL = 'FETCH_DISABLED_USER_SURVEY_REQUEST_FAIL';


export const FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST = 'FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST';
export const FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_SUCCESS = 'FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_SUCCESS';
export const FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_FAIL = 'FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_FAIL';


export const SUBMIT_TEMPLATE_EMAIL_REQUEST = 'USER_TEMPLATE_EMAIL_REQUEST';
export const SUBMIT_TEMPLATE_EMAIL_SUCCESS = 'USER_TEMPLATE_EMAIL_SUCCESS';
export const SUBMIT_TEMPLATE_EMAIL_COMPLETE = 'USER_TEMPLATE_EMAIL_COMPLETE';
export const SUBMIT_TEMPLATE_EMAIL_FAIL = 'USER_TEMPLATE_EMAIL_FAIL';


export const fetchReducer = (state={survey: {id: '', questions: []}, isLoading: false, error: null}, action) => {
  switch (action.type) {
    case FETCH_SURVEY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case FETCH_SURVEY_REQUEST_SUCCESS:
      return {
        survey: action.payload,
        isLoading: false,
        error: ''
      };
    case FETCH_SURVEY_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const fetchModifiedReducer = (state={modifiedSurvey: {id: '', questions: [],images: [],videos: [],question_order: []}, isLoading: false, error: null}, action) => {
    switch (action.type) {
        case FETCH_MODIFIED_SURVEY_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: ''
            };
        case FETCH_MODIFIED_SURVEY_REQUEST_SUCCESS:
            return {
                modifiedSurvey: action.payload,
                isLoading: false,
                error: ''
            };
        case FETCH_MODIFIED_SURVEY_REQUEST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};




export const fetchSecondaryDisabledReducer = (state={templateSecondaryDemo: {id: '', questions: [],images: [],videos: []}, isLoading: false, error: null}, action) => {
    switch (action.type) {
        case FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: ''
            };
        case FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_SUCCESS:
            return {
                templateSecondaryDemo: action.payload,
                isLoading: false,
                error: ''
            };
        case FETCH_DISABLED_SECONDARY_USER_SURVEY_REQUEST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};


export const fetchDisabledReducer = (state={templateDemo: {id: '', questions: [],images: [],videos: []}, isLoading: false, error: null}, action) => {
    switch (action.type) {
        case FETCH_DISABLED_USER_SURVEY_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: ''
            };
        case FETCH_DISABLED_USER_SURVEY_REQUEST_SUCCESS:
            return {
                templateDemo: action.payload,
                isLoading: false,
                error: ''
            };
        case FETCH_DISABLED_USER_SURVEY_REQUEST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};




export const submitReducer = (state={isDeleteSuccess: false, isLoading: false}, action) => {
  switch (action.type) {
    case SUBMIT_SURVEY:
      return {
        ...state,
          isDeleteSuccess: false,
        isLoading: true
      };
    case SUBMIT_SURVEY_SUCCESS:
      return {
        isLoading: false,
         isDeleteSuccess: true
      };
      case SUBMIT_SURVEY_COMPLETED:
          return {
              isLoading: false,
              isDeleteSuccess: false
          };
    default:
      return state;
  }
};

export const submitPDFReducer = (state={isPdfSuccess: false, isLoading: false}, action) => {
    switch (action.type) {
        case SUBMIT_PDF_SURVEY:
            return {
                ...state,
                isLoading: true
            };
        case SUBMIT_PDF_SURVEY_SUCCESS:
            return {
                isLoading: false,
                isPdfSuccess: true
            };
        default:
            return state;
    }
};


export const submitEmailReducer = (state={isEmailSuccess: false, isEmailLoading: false}, action) => {
    switch (action.type) {
        case SUBMIT_TEMPLATE_EMAIL_REQUEST:
            return {
                ...state,
                isEmailLoading: true,
                isEmailSuccess: false
            };
        case SUBMIT_TEMPLATE_EMAIL_SUCCESS:
            return {
                isEmailLoading: false,
                isEmailSuccess: true
            };
        case SUBMIT_TEMPLATE_EMAIL_COMPLETE:
            return {
                isEmailLoading: false,
                isEmailSuccess: true
            };
        default:
            return state;
    }
};



export const activateTemplateReducer = ( state ={survey: {id: '', questions: [],images:[],videos:[]},isActivationSuccess: false, isLoading: false}, action) => {
    switch (action.type) {
        case 'ACTIVATION_REQUEST':
            return {
                ...state,
                isLoading: true,
                isActivationSuccess: false
            };
        case 'ACTIVATION_REQUEST_SUCCESS':
            return {
                survey:action.payload,
                isLoading: false,
                isActivationSuccess: true
            };
        case 'ACTIVATION_REQUEST_FAIL':
            return {
                ...state,
                isLoading: false,
                isActivationSuccess: false
            };
        default:
            return state;
    }
}


export default combineReducers({
    fetchSurvey: fetchReducer,
    fetchModifiedSurvey:fetchModifiedReducer,
    submitSurvey: submitReducer,
    fetchDisabledSurvey:fetchDisabledReducer,
    fetchSecondaryDisabledSurvey :fetchSecondaryDisabledReducer,
    submitEmailTemplate:submitEmailReducer,
    submitPDFReducer:submitPDFReducer,
    activateTemplate :activateTemplateReducer,

})

/*export const getsurveyModified = (state) => {
    return state.fetchModifiedSurvey.modifiedSurvey;
};*/


export const getSurvey = (state) => state.fetchSurvey.survey;

export const getSurveyActivated = (state) => state.activateTemplate.survey;
export const getSurveyActivatedStatus = (state) => state.activateTemplate.isActivationSuccess;
export const getSurveyLoadingStatus = (state) => state.activateTemplate.isLoading;


export const getModifiedSurvey = (state) => state.fetchModifiedSurvey.modifiedSurvey;


export const getFetchStatus = (state) => {
  return state.fetchSurvey.isLoading;
};

export const getFetchError = (state) => {
  return state.fetchSurvey.error;
};

export const getSubmitStatus = (state) => {
  return state.submitSurvey.isDeleteSuccess;
};

export const getSubmitEmailStatus = (state) => {
    return state.submitEmailTemplate.isEmailSuccess;
};

export const getTemplateData = (state) => state.fetchDisabledSurvey;
export const getSecondaryTemplateData = (state) => state.fetchSecondaryDisabledSurvey;
/*export const getTemplateData = (state) => {
    return state.submitSurvey.state.fetchDisabledSurvey.templateDemo;
};*/
