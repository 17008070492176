import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegisterFormView from '../../components/RegisterForm';
import {registerRequest, registerRequestSuccess, registerRequestFail, registerDefault} from '../../actions/register';

class RegisterPage extends Component {
    constructor(props) {
        super(props);
    }
  componentWillReceiveProps(newProps) {
    if (newProps.isRegisterSuccess) {
      this.props.router.push('/login');
    }
  }

  render() {
    return (
        <div className="col-md-4 col-md-offset-4" style={{marginTop: '40px'}}>
          <RegisterFormView {...this.props}/>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isRegisterSuccess: state.register.isRegisterSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      return dispatch(registerRequest({
        username: values.username,
        email: values.email,
        password: values.password,
        role: values.role
      })).then(res => {
        dispatch(registerRequestSuccess(res));
        return Promise.resolve(res);
      }).catch(err => {
        dispatch(registerRequestFail(err));
        return Promise.reject(err);
      });
    },

  }
};

RegisterPage = connect(mapStateToProps, mapDispatchToProps)(RegisterPage);

export default RegisterPage;
