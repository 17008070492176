import React, { Component } from 'react';
import { connect } from 'react-redux';
/*import { loginRequest, loginRequestSuccess, loginRequestFail } from '../actions/login';*/
/*import {adminHome, userHome} from '../reducers/session';*/
import ForgetPasswordForm from "../../components/ForgetPasswordForm";
import {
    ForgetPasswordEmailSent,
    ForgetPasswordFail,
    ForgetPasswordRequest,
    ForgetPasswordSuccess
} from "../../actions/login";

class ForgetPasswordPage extends Component {
    componentWillReceiveProps(nextProps) {
       /* if (nextProps.isForgetSuccess) {

            /!*if(nextProps.currentUser.role === 'admin'){
                this.props.router.push(adminHome(nextProps.currentUser));
            }else{
                this.props.router.push(userHome(nextProps.currentUser));
            }*!/

        }*/
    }

    render() {
        return (
            <div className="col-md-4 col-md-offset-4" style={{marginTop: '40px'}}>
                {this.props.isForgetSuccess &&
                 <a style={{color:'green'}}>Email has been sucessfully sent please check your email for reset link</a>
                }
                <ForgetPasswordForm {...this.props}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isForgetSuccess: state.forget.isForgetSuccess
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values) => {
            return dispatch(ForgetPasswordRequest(values.email)).then(res => {
                dispatch(ForgetPasswordSuccess(res));
                setTimeout(
                    () => dispatch(ForgetPasswordEmailSent()),
                    3000
                );
                return Promise.resolve(res);
            }).catch(err => {
                dispatch(ForgetPasswordFail(err));
                return Promise.reject(err);
            });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordPage);
