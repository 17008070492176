import React from 'react';
import PropTypes from "prop-types";
import './SurveyList.css';
import UserSurveyItem from "./UserSurveyItem";

class UserSurveyListView extends React.Component {

    render() {
        let { userSurveys } = this.props;
        return (
            <div className="SurveyList row">
                <ul className="list-unstyled">
                    {userSurveys.map(userSurvey => {
                        return <li key={userSurvey._id} className="col-md-3"><UserSurveyItem userSurvey={userSurvey}/></li>
                    })}
                </ul>
            </div>
        );
    }
}

UserSurveyListView.propTypes = {
    userSurveys: PropTypes.array.isRequired
};

export default UserSurveyListView;
