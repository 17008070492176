import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {logout} from "../../actions/logout";
import {Alert} from "react-bootstrap";
import SecondarySurvey from "../../components/Survey/SecondaryActivatedSurvey";
import {
    getSecondaryTemplateData, getSurveyActivated, getSurveyActivatedStatus, getSurveyLoadingStatus,
} from "../../reducers/survey";
import {activateUserTemplate} from "../../actions/login";




class ActivationPage extends Component {
    loadData() {
        this.props.activateUserTemplate(this.props.email,this.props.templateId)
        this.props.logout();

    }

    componentWillMount() {

        this.loadData();





    }
    componentDidMount() {
    }




    constructor(props) {
        super(props);
        this.state = { templateSecondaryDemo: {} }


    }

    componentWillReceiveProps(newProps){
        if( !newProps.isLoading){
            if(!newProps.isActivationSuccess){
                setTimeout(() => {
                    this.props.router.push('register');
                }, 4000)
            }
        }
    }

    render() {

        return (
           <div>
              <div className="col-md-4 col-md-offset-4" style={{marginTop: '40px'}}>
                  {this.props && !this.props.isLoading  &&  !this.props.isActivationSuccess &&

                    <a style={{color:'green'}}>You are not registered please register to view the template</a>

                  }
                {/*<div>
                    {this.props && this.props.isActivationSuccess ? (
                        <a style={{color:'green'}}>Loading the template
                        </a>
                    ) : (
                        <a style={{color:'red'}}>Processing PLease wait for sometime..
                        </a>
                    )}
                </div>*/}

               {/* { this.props.isActivationSuccess ?(
                        <a style={{color:'green'}}>The template has been succesfully verified.
                            and please login again to view the template
                        </a>
                    ):(
                        <a style={{color:'green'}}>The template has been succesfully verified.
                    and please login again to view the template
                </a>)
                    '

                }*/}


            </div>
               <div className="container" style={{marginBottom:'40px'}}>
                   {this.props.survey ? '' : <Alert bsStyle="danger">No template Found</Alert>}
                   <div className="row SurveyPage">
                       <div className="col-md-8 col-md-offset-2 survey">
                           {this.props.survey &&
                           <SecondarySurvey {...this.props}/>
                           }
                       </div>
                       {/*  <div className="col-md-8 col-md-offset-2 survey">
                  {this.props.templateDemo &&
                  <Email {...this.props}/>
                  }
            </div>*/}
                   </div>
               </div>
           </div>
        )
    }
}

const mapStateToProps = (state,{ params }) => {
    return {
        email:params.email,
        key:params.key,
        templateId:params.templateId,
        survey: getSurveyActivated(state.survey),
        currentUser: state.session.user,
        isLoading:getSurveyLoadingStatus(state.survey),
        isActivationSuccess:getSurveyActivatedStatus(state.survey),


    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        logout: bindActionCreators(logout, dispatch),
        activateUserTemplate: bindActionCreators(activateUserTemplate, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationPage);
