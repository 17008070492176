import * as React from 'react';
import {withFormik, Field, Formik as setFieldValue} from 'formik';
import {AutoAffix} from "../pages/user/EditSurveyPage";
import {reject} from "lodash";

type Props = {
    onSubmit: func
};

const renderInput = ({field, form: { touched, errors }, ...props}) =>
    <div>
        <input {...field.input} {...field} {...props} className="form-control" />
        {
            touched[field.name] &&
            errors[field.name] &&
            <span className="help-block">{field.meta.error}</span>
        }
    </div>;

class TemplateForm extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state = {
            files: [],
            videos:[]
        }
    }


    fileSelectedHandler = async (e) => {
        try {
            await  this.setState({ files: [...this.state.files, ...e.target.files] });
            this.props.setFieldValue("files",this.state.files);
        } catch (error) {
            console.log(error);
        }

    }
    fileVideoHandler = async (e) => {

        try {
            await  this.setState({ videos: [...this.state.videos, ...e.target.files] });
            this.props.setFieldValue("videos",this.state.videos);
        } catch (error) {
            console.log(error);
        }
    }


    deleteImage = (file, index) => {
        const newlist = [].concat(this.state.files) // Clone array with concat or slice(0)
        newlist.splice(index, 1);
        this.setState({files: newlist});
    };

    deleteVideo = (file, index) => {
        const newlist = [].concat(this.state.videos) // Clone array with concat or slice(0)
        newlist.splice(index, 1);
        this.setState({videos: newlist});
    };

    render() {
        let { isSubmitting, handleSubmit, errors,modifiedSurvey,files } = this.props;
        let orderedQuestions = modifiedSurvey.question_order.map(id =>  modifiedSurvey.questions[id]);
        return (

            <form onSubmit={handleSubmit}>
                <legend>{modifiedSurvey.title}</legend>
                {errors.message && <div className="alert alert-danger" role="alert">{errors.message}</div>}
                {orderedQuestions && orderedQuestions.length > 0  && orderedQuestions.map((question, index) => {
                    return (

                        <div className="form-group">
                            <label htmlFor="">{question.title}</label>
                            <Field  style={{width:'100%'}}
                                    name={'answer'+index}
                                    component={question.type=== 'MUTLI_LINE_TEXT'? 'textarea':renderInput}
                                    placeholder={question.placeholder}
                                    type={question.type=== 'MUTLI_LINE_TEXT'? 'textarea':'text'}/>
                        </div>
                    );
                })}
                <div className="col-lg-8" style={{padding:20}}>
                    <div className="my-3">
                        <h4>Please add some images(Multiple images one by one)</h4>
                        <label htmlFor="files">Select file</label>
                        <input type="file" name="files" multiple onChange={this.fileSelectedHandler}  />
                        { this.state.files && this.state.files.length > 0  && this.state.files.map((file, index) => {
                            return (
                                <div className="col-md-2" style={{padding:20}}>
                                    <span className="glyphicon glyphicon-remove" aria-hidden="true" onClick={()=>this.deleteImage(file, index)}  />
                                    <img className="img-responsive" src={URL.createObjectURL(file)}   />
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
                <div className="col-lg-8" style={{padding:20}}>
                    <div className="my-3">
                        <h4>Please add some videos(Multiple videos one by one mp4 format)</h4>
                        <label htmlFor="videos">Select file</label>
                        <input type="file" name="videos" multiple onChange={this.fileVideoHandler}  />
                        { this.state.videos && this.state.videos.length > 0  && this.state.videos.map((file, index) => {
                            return (
                                <div className="col-md-2" style={{padding:20}}>
                                    <span className="glyphicon glyphicon-remove" aria-hidden="true" onClick={()=>this.deleteVideo(file, index)}  />
                                    {/* <video className="img-responsive" src={URL.createObjectURL(file)}   />*/}
                                    <video width="320" height="240" controls>
                                        <source src={URL.createObjectURL(file)} type="video/mp4"/>
                                    </video>
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
                {isSubmitting ?
                    <button className="btn btn-primary" type="submit" disabled>Loading...</button> :
                    <button className="btn btn-primary" type="submit">Save Template</button>}
            </form>
        );
    }
}
export default withFormik({
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        let newTemplate = props.modifiedSurvey.question_order.map(id =>  props.modifiedSurvey.questions[id]);

        let newTemplate2=newTemplate.map((currElement, index) =>  currElement['answer']=values['answer'+index]);

        let newTemplate3={};
        const images=[];
        const videos=[];
        let filteredFiles=[];
        let filteredVideos=[];



        if(values.files && values.files.length > 0){
            filteredFiles=values.files.filter(file => file['name'] && file['type']);
        }
        if(values.videos && values.videos.length > 0){
            filteredVideos=values.videos.filter(video => video['name'] && video['type']);
        }


        if(values.files && values.files.length>0){
            newTemplate3 = values.files.filter(file => !file._id && !file['Location']).map(file => {
                return new Promise((resolve, reject) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {

                        resolve(reader.result);
                    };
                })
            });
        }else{
            values.files=[];
        }
        let newTemplate4={};
        if(values.videos && values.videos.length>0){
            newTemplate4 = values.videos.filter(file => !file._id && !file['Location']).map(video => {
                return new Promise((resolve, reject) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(video);
                    reader.onload = function () {

                        resolve(reader.result);
                    };
                })
            });
        }else{
            values.videos=[];
        }


        if(filteredFiles.length > 0 || filteredVideos.length > 0){

            let totalArray=[newTemplate3,newTemplate4]
            if(!(newTemplate3.length > 0)){
                totalArray=[newTemplate4]
            }
            if(!(newTemplate4.length > 0)){
                totalArray=[newTemplate3]
            }

            if(newTemplate3 && newTemplate4){
                const promise4all = Promise.all(
                    totalArray.map(function(innerPromiseArray) {
                        return Promise.all(innerPromiseArray);
                    })
                );

                promise4all.then(function(results) {

                    let type;
                    let exactType;
                    let mimeType2;
                    if(results.length === 1){
                        type = results[0].toString().split(';')[0].split(':')[1];
                        exactType = type.toString().split('/')[0];
                        mimeType2 = results[0].toString().match(/[^:]\w+(?=;|,)/);

                    }

                    let imageArray=[];
                    let videoArray=[];



                    if(filteredFiles.length > 0){
                        imageArray=results[0];
                    }
                    if(filteredVideos.length > 0){ console.log('newTemplate3,newTemplate4',newTemplate3,newTemplate4);
                        videoArray=results[1];
                    }
                    if(exactType === 'image'){
                        imageArray=results[0];
                    }else if(exactType === 'video'){
                        videoArray=results[0];
                    }

                    props.modifiedSurvey['images']=imageArray;
                    props.modifiedSurvey['videos']=videoArray;
                    props.modifiedSurvey['userId']=values.currentUser.id
                    props.onSubmit(props.modifiedSurvey).then((data) => {
                        setSubmitting(false);
                    }, (err) => {
                        setSubmitting(false);
                        setErrors({
                            message: err.response.data.message
                        })
                    })
                    return results;
                })
            }

        }else{
            props.modifiedSurvey['images']=images;
            props.modifiedSurvey['videos']=videos;
            props.modifiedSurvey['userId']=values.currentUser.id
            props.onSubmit(props.modifiedSurvey).then((data) => {
                setSubmitting(false);
            }, (err) => {
                setSubmitting(false);
                setErrors({
                    message: err.response.data.message
                })
            })

        }
    },

})(TemplateForm);
