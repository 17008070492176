import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {deleteSurvey, updateModifiedSurvey, updateSurvey} from '../actions/edit_survey';
import { assembleSurvey, getSurvey } from '../reducers/edit_survey';
import { Path } from '../routes';
import EditFooterView from './EditPanel/EditFooter';

class EditFooter extends React.Component {
  componentWillUnmount() {
    this.props.resetDeleteState();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isUpdateSuccess) {
        this.props.router.push('user/surveys');
      /*this.props.push(Path.getUserModifiedSurvey(nextProps.survey));*/
    }
  }

  render() {
    return <EditFooterView {...this.props}/>
  }
}

const mapStateToProps = (state, { router }) => {
  return {
      currentUser: state.session.user,
    survey: assembleSurvey(getSurvey(state.edit_survey)),
    isDeleteSuccess: state.edit_survey.deleteSurvey.isSuccess,
    isUpdateSuccess: state.edit_survey.updateSurvey.isSuccess,
    push: router.push
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDelete: bindActionCreators(deleteSurvey, dispatch),
    onSave: bindActionCreators(updateModifiedSurvey, dispatch),
    resetDeleteState: () => dispatch({
      type: 'RESET_DELETE_SURVEY_REQUEST'
    })
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditFooter));
