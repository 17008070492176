import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserSurveyListView from './UserSurveyListView';
import { fetchUserSurveysRequest } from '../../actions/userSurveys';
import userSurveys from "../../reducers/userSurveys";

class   UserSurveyList extends React.Component {
    loadData() {
        this.props.fetchUserSurveys(this.props.currentUser);
    }

    componentWillMount() {
        this.loadData();
    }

    renderLoading() {
        return <div>Loading...</div>
    }

    renderError() {
        return <div>Load Error</div>
    }

    render() {
        const { userSurveys, isLoading, loadError } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        if (loadError) {
            return this.renderError();
        }

        return (
           <div>
                <UserSurveyListView userSurveys={userSurveys}/>
           </div>

        );
    }
}

const mapStateToProps = (state) => (
    {
    userSurveys: state.userSurveys.userSurveys,
    isLoading: state.surveys.isLoading,
    loadError: state.surveys.error,
    currentUser: state.session.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserSurveys: bindActionCreators(fetchUserSurveysRequest, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSurveyList);
