export default function(state={isForgetSuccess: false, isLoading: false}, action) {
  switch (action.type) {
    case 'FORGET_REQUEST':
      return {
        ...state,
        isLoading: true
      };
    case 'FORGET_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isForgetSuccess: true
      };
    case 'FORGET_REQUEST_FAIL':
      return {
        ...state,
        isLoading: false,
        isForgetSuccess: false
      };
      case 'FORGET_REQUEST_EMAIL_SENT':
          return {
              ...state,
              isLoading: false,
              isForgetSuccess: false
          };
    default:
    return state;
  }
};

