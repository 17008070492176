import React from 'react';
import { Link } from 'react-router';
import { Path } from '../../routes';
import './SurveyItem.css';
import {connect} from "react-redux";

import edit from '../../src_assets/images/edit.png';
import deleteIcon from '../../src_assets/images/delete.png';
import {Button} from "react-bootstrap";
import {fetchData} from "../../actions/data";
import {deleteSurvey, fetchSurvey, resetDeleteState, updateSurvey} from "../../actions/edit_survey";
import {bindActionCreators} from "redux";

class SurveyItem extends React.Component {

  componentDidUpdate(prevProps, prevState) {
      if (this.props.isSuccess) {
          this.props.resetCreateSurvey();
          this.props.router.push(Path.editSurvey(this.props.newSurvey));
      }
  }
  render() {
    let { currentUser,survey, survey: { title },deleteSurvey } = this.props;
    return (
        <div >
                  { this.props.currentUser.role === 'admin'
                      ?
                      <div className="SurveyItem" style={{padding: '24px'}}>
                          <Link className='image-edit-icon' to={Path.editAdminSurvey(survey)}><img src={edit}  height="20" width="20" /></Link>
                          <Link style={{cursor:'pointer'}} className='image-delete-icon' onClick={() => {
                              deleteSurvey(survey._id,currentUser);
                          }} > <img  src={deleteIcon}  height="20" width="20" /></Link>
                          <span style={{color: 'blue'}}>{title}</span>
                      </div>
                      :
                      <div className="SurveyItem" >
                          <Link to={Path.primarySurvey(survey)}>{title}</Link>
                      </div>
                  }
        </div>
    );
  }
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.session.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteSurvey: bindActionCreators(deleteSurvey, dispatch)
    }

};

SurveyItem.propTypes = {};
SurveyItem.defaultProps = {};



export default connect(mapStateToProps, mapDispatchToProps)(SurveyItem);