/* global location */
/* eslint no-restricted-globals: ["off", "location"] */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Navbar, Nav, NavItem,Dropdown ,MenuItem} from 'react-bootstrap';
import './TopNavBar.css';

class TopNavBar extends Component {
  loginView() {
    let { currentUser } = this.props;
    return (
        <Nav pullRight>
            <NavItem href="#/logout">Logout</NavItem>
         {/* <Dropdown.Menu eventKey={3} title={currentUser.username} id="basic-nav-dropdown">

            <MenuItem divider/>
            <MenuItem href="#/logout">Logout</MenuItem>
          </Dropdown.Menu>*/}
        </Nav>
    );
  }

  unLoginView() {
    return (
        <Nav pullRight>
          <NavItem eventKey={1} href="#/login">Login</NavItem>
          <NavItem eventKey={1} href="#/register">Register</NavItem>
        </Nav>

    );
  }

  render() {
    //eslint-disable-next-line
    let pathname = location.pathname;
    let { currentUser } = this.props;

    return (
        <Navbar className="TopNavBar">
          <Navbar.Header>
            <Navbar.Brand>
              <a href={pathname}>
                Reporting Business
              </a>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            {currentUser ? this.loginView() : this.unLoginView()}
          </Navbar.Collapse>
        </Navbar>
    )
  }
}

var mapStateToProps = (state,router) => {
  return {
    currentUser: state.session.user,
    push: router.push
  }
};


TopNavBar = withRouter(connect(mapStateToProps)(TopNavBar));

export default TopNavBar;
